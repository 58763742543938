import styled from "@emotion/styled";
import { Button, Modal } from "antd";
import { useLocalStorage } from "context/localstorage-context";
import { useWallet } from "context/wallet-context";
import { useMemo, useState } from "react";
import { PosStaker } from "types/pos-staker";

export default function DelegateConfirm({
  visible,
  onCancel,
  stakerInfo,
  amount,
  action,
  type,
}: {
  amount: string;
  visible: boolean;
  onCancel: () => void;
  stakerInfo: PosStaker;
  action: (arg: string) => void;
  type?: string;
}) {
  const { address } = useWallet();
  const [loadingBTn, setLoadingBtn] = useState(false);
  const { theme } = useLocalStorage();
  const isdark = useMemo(() => theme === "dark", [theme]);

  const onOk = () => {
    action(amount);
    setLoadingBtn(true);
  };

  const getRewardTime = () => {
    const time = new Date(Date.now() + 24 * 3600 * 1000 * 3);
    const utc = Date.UTC(
      time.getUTCFullYear(),
      time.getUTCMonth(),
      time.getUTCDate(),
      8
    );
    return new Date(utc).toUTCString();
  };

  return (
    <Modal
      className={isdark ? "darkModal" : "lightModal"}
      maskClosable={true}
      closable={true}
      destroyOnClose={true}
      style={{ background: "#051F31", borderRadius: "16px" }}
      width={700}
      mask={true}
      open={visible}
      title={
        <Title className="text-light-0 dark:text-white">
          {type ? type : "Confirm Transaction"}
        </Title>
      }
      footer={null}
      onCancel={onCancel}
    >
      <Part isdark={isdark} className="bg-white dark:bg-common-1">
        <PartTitle className="text-light-0 dark:text-white">
          Validator's Account
        </PartTitle>
        <LineStyle isdark={isdark} />
        <DetailInfo>
          <RowStyle>
            <span>Name</span>
            <AddrText>
              <ImgStyle
                width={25}
                src={`data:image/${stakerInfo.iconType};base64,${stakerInfo.iconData}`}
                alt="icon"
              />
              {stakerInfo.name}
            </AddrText>
          </RowStyle>
          <RowStyle>
            <span>Address</span>
            <AddrText>{stakerInfo.address}</AddrText>
          </RowStyle>
          <RowStyle>
            <span>Lock Period</span>
            <span>
              <span style={{ color: "#2FBDF4" }}>{stakerInfo.lockEpochs} </span>
              {stakerInfo.lockEpochs > 1 ? "DAYS" : "DAY"}
            </span>
          </RowStyle>
          <RowStyle>
            <span>Delegation Fee(%)</span>
            <span>{stakerInfo.feeRateFmt}</span>
          </RowStyle>
        </DetailInfo>
      </Part>
      <Part isdark={isdark} className="bg-white dark:bg-common-1">
        <PartTitle className="text-light-0 dark:text-white">
          My Account
        </PartTitle>
        <LineStyle isdark={isdark} />
        <DetailInfo>
          <RowStyle>
            <span>Amount</span>
            <span>
              <span style={{ color: "#2FBDF4" }}>{amount || "0"} </span>
              WAN
            </span>
          </RowStyle>
          <RowStyle>
            <span>Address</span>
            <AddrText>{address}</AddrText>
          </RowStyle>
        </DetailInfo>
      </Part>
      {!!type && (
        <Part
          isdark={isdark}
          className="bg-white dark:bg-common-1"
          style={{ padding: "2rem" }}
        >
          <span style={{ color: "#2FBDF4", fontSize: "14px" }}>
            Your delegated WAN will return to your wallet address on{" "}
            {getRewardTime()}
          </span>
        </Part>
      )}
      <BtnArea isdark={isdark}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onOk} loading={loadingBTn}>
          Confirm
        </Button>
      </BtnArea>
    </Modal>
  );
}

const Title = styled.span`
  font-size: 24px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
`;

const BtnArea = styled.div<{
  isdark: boolean;
}>`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 20px;

  .ant-btn:first-of-type {
    background: ${(props) =>
      props.isdark ? "rgba(255, 255, 255, 0.1)" : "#E5E5E5"};
    border-radius: 16px;
    color: ${(props) => (props.isdark ? "#818d96" : "#999")};
  }
  .ant-btn:first-of-type:hover {
    background: ${(props) =>
      props.isdark ? "rgba(255, 255, 255, 0.1)" : "#E5E5E5"};
  }
  .ant-btn {
    width: calc((100% - 20px) / 2);
    border-radius: 16px;
    height: 60px;
    color: white;
    border: none;
    background: #0f68aa;
  }
  .ant-btn:hover {
    background: #0f68aa;
  }
`;

const Part = styled.div<{
  isdark: boolean;
}>`
  border-radius: 16px;
  opacity: 1;
  border: ${(props) =>
    props.isdark ? "1px solid rgba(255, 255, 255, 0.1)" : "1px solid #EBEFF2"};
  margin-bottom: 2rem;
  padding-top: 2rem;

  span {
    color: #818d96;
  }
`;

const PartTitle = styled.div`
  font-size: 16px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  line-height: 19px;
  background-clip: text;
  margin-left: 2rem;
  text-align: left;
`;

const LineStyle = styled.div<{
  isdark: boolean;
}>`
  width: 100%;
  margin: 2rem 0;
  opacity: 0.2;
  border: ${(props) =>
    props.isdark ? "1px solid rgba(255, 255, 255, 0.1)" : "1px solid #EBEFF2"};
`;

const DetailInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 2rem 2rem;
  gap: 1rem;
`;

const RowStyle = styled.div`
  color: #757575;
  display: flex;
  justify-content: space-between;

  p {
    font-size: 16px;
  }
`;

const ImgStyle = styled.img`
  border-radius: 50%;
  border: 1px solid #757575;
  margin-right: 3px;
  display: inline-block;
`;

const AddrText = styled.span`
  @media (max-width: 768px) {
    width: 70%;
    white-space: break-spaces;
    text-align: right;
  }
`;
