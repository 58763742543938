import styled from "@emotion/styled";
import { Button, Modal } from "antd";
import successIcon from "assets/success.png";
import failedIcon from "assets/failed.png";

interface StatusType {
  success: string;
  failed: string;
}

const Status = {
  success: "Success",
  failed: "Failed",
};

const StatusTypeIcon = {
  success: successIcon,
  failed: failedIcon,
};

export default function StatusModal({
  type,
  visible,
  onCancel,
}: {
  type: keyof StatusType;
  visible: boolean;
  onCancel: () => void;
}) {
  return (
    <Modal
      maskClosable={true}
      closable={true}
      destroyOnClose={true}
      width={600}
      mask={true}
      open={visible}
      title={null}
      footer={null}
      onCancel={onCancel}
      className="statusModal"
    >
      <StatusIcon src={StatusTypeIcon[type]} alt="status" width="200" />
      <StatusText>{Status[type]}</StatusText>
      <BtnArea>
        <Button block onClick={onCancel}>
          {type === "success" ? "View on WanScan" : "OK"}
        </Button>
      </BtnArea>
    </Modal>
  );
}

const StatusIcon = styled.img`
  margin-top: 5rem;
`;

const BtnArea = styled.div`
  .ant-btn {
    border-radius: 16px;
    height: 60px;
    color: white;
    border: none;
    background: linear-gradient(90deg, #f4982f 0%, #14daca 100%);
  }
  .ant-btn:hover {
    background: linear-gradient(90deg, #f49821 0%, #14dacc 100%);
  }
`;

const StatusText = styled.div`
  color: #14daca;
  font-size: 16px;
  margin: 1rem 0 2rem 0;
`;
