import { useMemo } from "react";
import { useQuery } from "react-query";
import useWebSocket from "react-use-websocket";

export const useIwanSocket = () => {
  const socketUrl = `wss://${
    process.env.REACT_APP_CHAINID === "888" ? "api" : "apitest"
  }.wanchain.org:8443/ws/v3/${process.env.REACT_APP_API_KEY}`;
  const instance = useWebSocket(socketUrl, {
    onOpen: () => {},
    shouldReconnect: (closeEvent) => true,
  });
  return useMemo(() => instance, [instance]);
};

export const useIwan = (funName: string, params: object, enabled: boolean) => {
  const { sendJsonMessage, readyState, lastMessage } = useIwanSocket();

  useQuery(
    funName,
    () =>
      sendJsonMessage({
        jsonrpc: "2.0",
        method: funName,
        params,
        id: 1,
      }),
    {
      enabled: readyState === 1 && enabled,
    }
  );
  return useMemo(() => {
    if (lastMessage && lastMessage.data) {
      const dataObj = JSON.parse(lastMessage.data);
      return dataObj.result;
    } else {
      return null;
    }
  }, [lastMessage]);
};
