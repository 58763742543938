import styled from "@emotion/styled";
import { Row } from "components/lib";
import { ConnectWallet } from "components/connect-wallet";
import WanPrice from "components/wan-price";
import ThemeSwitch from "components/theme-switch";

export default function PageHeader() {
  return (
    <Header between={true}>
      <HeaderLeft gap={true}></HeaderLeft>
      <HeaderRight>
        <ThemeSwitch inSiderbar={false} />
        <WanPrice />
        <ConnectWallet />
      </HeaderRight>
    </Header>
  );
}

const Header = styled(Row)`
  padding: 0 3.2rem;
  z-index: 1;
  flex: 0 0 auto;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    margin-bottom: 0;
    padding: 0;
  }
`;

const HeaderLeft = styled(Row)``;
const HeaderRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
