import styled from "@emotion/styled";
import WanPos from "assets/wanPos.png";
import Rewards from "assets/rewards.png";
import MyRewards from "assets/myrewards.png";
import PosLists from "./pos-lists";
import { useMemo } from "react";
import {
  useQueryPosStakeInfo,
  useQueryStatsInfo,
  useQueryTokenPrice,
} from "utils/queries";
import numeral from "numeral";
import { useDelegatorStakeInfo, useDelegatorTotalIncentive } from "./pos-data";
import BigNumber from "bignumber.js";
import { useLocalStorage } from "context/localstorage-context";
import { useWallet } from "context/wallet-context";

export default function Pos() {
  const { address } = useWallet();
  const { data: statsInfo } = useQueryStatsInfo();
  const { data: posStakeInfo } = useQueryPosStakeInfo();
  const { theme } = useLocalStorage();
  const delegatorStakeInfo = useDelegatorStakeInfo();
  const delegatorTotalIncentive = useDelegatorTotalIncentive();
  const { data: tokensPrice, isLoading: tokensPriceLoading } =
    useQueryTokenPrice();

  const isdark = useMemo(() => theme === "dark", [theme]);

  const stakerList = useMemo(() => {
    if (
      posStakeInfo &&
      posStakeInfo.stakers &&
      posStakeInfo.stakers.length > 0 &&
      (!address || delegatorStakeInfo.done)
    ) {
      posStakeInfo.stakers.forEach((i: any) => {
        i.myDelegate = "0";
        i.myTotalIncentive = "0";
        delegatorStakeInfo.forEach((j: any) => {
          if (i.address.toLowerCase() === j.address.toLowerCase()) {
            i.myDelegate = j.amount.toString();
          }
        });
        delegatorTotalIncentive.forEach((t: any) => {
          if (i.address.toLowerCase() === t.address.toLowerCase()) {
            i.myTotalIncentive = new BigNumber(i.myTotalIncentive)
              .plus(t.amount)
              .toString();
          }
        });
      });
      return posStakeInfo.stakers;
    } else {
      return [];
    }
  }, [address, delegatorStakeInfo, delegatorTotalIncentive, posStakeInfo]);

  const WanPrice = useMemo(() => {
    if (!tokensPriceLoading && tokensPrice) {
      return tokensPrice.wanchain?.usd || "0";
    } else {
      return "0";
    }
  }, [tokensPrice, tokensPriceLoading]);

  const myAllDelegates = useMemo(() => {
    return delegatorStakeInfo.reduce(
      (prev: string, i: { amount: string }) =>
        new BigNumber(prev).plus(i.amount).toString(),
      0
    );
  }, [delegatorStakeInfo]);

  const myAllDelegatesInUsd = useMemo(() => {
    const tmp = new BigNumber(WanPrice)
      .multipliedBy(myAllDelegates || "0")
      .toString();
    return numeral(tmp).format("0,0");
  }, [WanPrice, myAllDelegates]);

  const myTotalIncentive = useMemo(() => {
    return delegatorTotalIncentive.reduce(
      (prev: string, i: { amount: string }) =>
        new BigNumber(prev).plus(i.amount).toString(),
      0
    );
  }, [delegatorTotalIncentive]);

  const posTvl = useMemo(() => {
    if (statsInfo && statsInfo.pos) {
      return statsInfo.pos.curTotalAmount;
    } else {
      return 0;
    }
  }, [statsInfo]);

  const posTvlInUsd = useMemo(() => {
    if (statsInfo && statsInfo.pos) {
      const posTvl = new BigNumber(statsInfo.pos.curTotalAmountRaw)
        .dividedBy(Math.pow(10, 18))
        .toString(10);
      const posTvlNumber = numeral(posTvl).value();
      const usdTvl = new BigNumber(WanPrice)
        .multipliedBy(posTvlNumber || "0")
        .toString();
      return numeral(usdTvl).format("0,0");
    } else {
      return 0;
    }
  }, [WanPrice, statsInfo]);

  const posRewardRate = useMemo(() => {
    if (statsInfo && statsInfo.pos) {
      return statsInfo.pos.curReturnRate;
    } else {
      return 0;
    }
  }, [statsInfo]);

  return (
    <Container>
      {/* Total  */}
      <PosStatistics className="bg-white dark:bg-common-1">
        <StatisticsTvl>
          <StatisticsTitle className="text-light-0 dark:text-dark-0">
            PoS TVL
            <PosTvlInUsd>
              <Data1>{posTvlInUsd} USD</Data1>
            </PosTvlInUsd>
          </StatisticsTitle>
          <Con>
            <Img src={WanPos} alt="WanPos" />
            <Data>
              <div className="text-light-111 dark:text-white">
                {posTvl} <Data1>WAN</Data1>
              </div>
              <PosTvlInUsdMedia>
                <Data1>{posTvlInUsd} USD</Data1>
              </PosTvlInUsdMedia>
            </Data>
          </Con>
        </StatisticsTvl>
        <LineStyle isdark={isdark} />
        <StatisticsAvg>
          <StatisticsTitle className="text-light-0 dark:text-dark-0">
            Average APR
          </StatisticsTitle>
          <Con>
            <Img src={Rewards} alt="Rewards" />
            <Data className="text-light-222 dark:text-white">
              {posRewardRate}
            </Data>
          </Con>
        </StatisticsAvg>
      </PosStatistics>

      <Gap />

      {/* Personal  */}
      {new BigNumber(myAllDelegates).gt("0") && (
        <PosStatistics className="bg-white dark:bg-common-1">
          <StatisticsTvl>
            <StatisticsTitle className="text-light-0 dark:text-dark-0">
              My Delegations
              <PosTvlInUsd>
                <Data1>{myAllDelegatesInUsd} USD</Data1>
              </PosTvlInUsd>
            </StatisticsTitle>
            <Con>
              <Img src={WanPos} alt="WanPos" />
              <Data>
                <div className="text-light-111 dark:text-white">
                  {numeral(myAllDelegates)
                    .format("0,0.00")
                    .replace(/\.?0+$/, "")}{" "}
                  <Data1>WAN</Data1>
                </div>
                <PosTvlInUsdMedia>
                  <Data1>{myAllDelegatesInUsd} USD</Data1>
                </PosTvlInUsdMedia>
              </Data>
            </Con>
          </StatisticsTvl>
          <LineStyle isdark={isdark} />
          <StatisticsAvg>
            <StatisticsTitle className="text-light-0 dark:text-dark-0">
              My Rewards
            </StatisticsTitle>
            <Con>
              <Img src={MyRewards} alt="Rewards" />
              <Data>
                <div className="text-light-222 dark:text-white">
                  {numeral(myTotalIncentive)
                    .format("0,0.00")
                    .replace(/\.?0+$/, "")}{" "}
                  <Data1>WAN</Data1>
                </div>
              </Data>
            </Con>
          </StatisticsAvg>
        </PosStatistics>
      )}
      <PosLists stakerList={stakerList} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 2rem 8%;
  text-align: center;
  height: 100%;
`;

const PosStatistics = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  backdrop-filter: blur(50px);
  padding: 0 2rem;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StatisticsTvl = styled.div`
  width: 50%;
  padding: 2rem 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PosTvlInUsd = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: inline-block;
  }
`;

const PosTvlInUsdMedia = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Con = styled.div`
  position: relative;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StatisticsTitle = styled.div`
  font-size: 18px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

const StatisticsAvg = styled.div`
  width: 50%;
  padding: 2rem 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Img = styled.img`
  width: 3rem;
  margin-top: 5px;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  position: absolute;
`;

const Data = styled.div`
  font-size: 28px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  justify-content: space-between;
  display: flex;
  width: calc(100% - 4rem);
  padding: 0 1rem 0 4rem;
`;

const Data1 = styled.span`
  color: #808080;
  font-size: 15px;
`;

const LineStyle = styled.div<{
  isdark: boolean;
}>`
  height: 100%;
  border-left: ${(props) =>
    props.isdark ? "1px solid rgba(255, 255, 255, 0.1)" : "1px solid #EBEFF2"};
  margin: 0 2rem;

  @media (max-width: 768px) {
    width: 100%;
    border-bottom: ${(props) =>
      props.isdark
        ? "1px solid rgba(255, 255, 255, 0.1)"
        : "1px solid #EBEFF2"};
    margin: 2rem 0;
  }
`;

const Gap = styled.div`
  width: 100%;
  margin: 1rem 0;
`;
