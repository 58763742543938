import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import { useHttp } from "utils/http";
import { getCorrespondChain, getDecimal, tokensName } from "config";
import { useWallet } from "context/wallet-context";
import { useCallback, useMemo, useState } from "react";
import { config } from "config";
import { TokenType } from "types/token-type";
import { formatBalance, formatValue, fromWei, validGroupId } from "utils";
import { callMetamaskFunc } from "utils/metamask";
import _ from "lodash";
import {
  useCallContractFunc,
  useConfig,
  useErc20Contract,
  useRecordAirDropEvent,
  useRecordAirDropFunc,
  useStoremanGroupDelegateFunc,
  useWanWeb3,
} from "utils/useWeb3";
import { ReceiptResult, TransParams } from "types/trans-params";
import { AirDropEvent } from "types/event-values";
import { useIwan } from "./useWebSocket";
import { StoremanInfoType } from "types/storeman-type";

const WANSCAN_API = process.env.REACT_APP_WANSCAN_API;

export const useQueryBalance = (symbol: TokenType, now: boolean) => {
  const { address, web3 } = useWallet();
  const [contractInstance] = useErc20Contract(symbol);

  return useQuery<string>(
    ["balance", symbol],
    () =>
      symbol === "WAN"
        ? web3.eth
            .getBalance(address)
            .then((value: string) => web3.utils.fromWei(value))
        : contractInstance.methods
            .balanceOf(address)
            .call()
            .then((balance: string) =>
              formatBalance(balance, getDecimal.get(symbol))
            ),
    {
      enabled: !!address && now,
    }
  );
};

export const useQueryDepositAll = () => {
  const client = useCallContractFunc();
  return useQuery(["MultiCoinStakeDelegate", "getDepositAll"], () =>
    client("MultiCoinStakeDelegate", "getDepositAll", {})
  );
};

export const useQueryCurrentStoremanGroupID = () => {
  const client = useCallContractFunc();
  return useQuery(["MultiCoinStakeDelegate", "getCurrentStoremanGroupID"], () =>
    client("MultiCoinStakeDelegate", "getCurrentStoremanGroupID", {})
  );
};

export const useQueryStoremanGroupStatus = (id: string) => {
  const client = useCallContractFunc();
  return useQuery(
    ["MultiCoinStakeDelegate", `getStoremanGroupStatus_${id}`],
    () =>
      client("MultiCoinStakeDelegate", "getStoremanGroupStatus", {
        params: [id],
      }),
    {
      enabled: validGroupId(id),
    }
  );
};

export const useQueryTokenPrice = () => {
  const client = useHttp();
  return useQuery(["FetchTokenPrice"], () =>
    client({
      params: {
        ids: tokensName.join(),
        vs_currencies: "usd",
      },
    })
  );
};

export const useQueryStakers = (symbol: TokenType) => {
  const { address, networkId } = useWallet();
  const client = useCallContractFunc();
  const tokenAddr = useMemo(
    () =>
      address && config[networkId]
        ? config[networkId].tokenAddrArr[symbol]
        : "",
    [address, networkId, symbol]
  );

  const { data: staker, isSuccess: isSuccessStaker } = useQuery(
    ["MultiCoinStakeDelegate", `stakers_${symbol}`],
    () =>
      client("MultiCoinStakeDelegate", "stakers", {
        params: [tokenAddr, address],
      }),
    {
      enabled: !!address && !!tokenAddr,
    }
  );
  const id = useMemo(() => staker && staker.quitGroupId, [staker]);
  const { data: smgStatus, isSuccess: isSuccessSmgStatus } = useQuery(
    ["MultiCoinStakeDelegate", `getStoremanGroupStatus_${id}`],
    () =>
      client("MultiCoinStakeDelegate", "getStoremanGroupStatus", {
        params: [id],
      }),
    {
      enabled: validGroupId(id),
    }
  );

  return { staker, smgStatus, isSuccessStaker, isSuccessSmgStatus };
};

export const useQueryWanEarned = (tokenType: TokenType) => {
  const { address, networkId } = useWallet();
  const client = useRecordAirDropFunc();
  const tokenAddr = useMemo(
    () =>
      address && config[getCorrespondChain.get(networkId.toString()) || ""]
        ? config[getCorrespondChain.get(networkId.toString()) || ""]
            .tokenAddrArr[tokenType]
        : "",
    [address, networkId, tokenType]
  );
  return useQuery(
    ["RecordAirDrop", `userReward_${tokenType}`],
    () => client("userReward", [tokenAddr, address || ""]),
    {
      enabled: !!address && !!tokenAddr,
    }
  );
};

export const useQueryAirDropEvent = (tokenType: TokenType) => {
  const { address, networkId } = useWallet();
  const client = useRecordAirDropEvent();
  const wanWeb3 = useWanWeb3();
  const tokenAddr = useMemo(
    () =>
      address && config[getCorrespondChain.get(networkId.toString()) || ""]
        ? config[getCorrespondChain.get(networkId.toString()) || ""]
            .tokenAddrArr[tokenType]
        : "",
    [address, networkId, tokenType]
  );
  const { data: airdropArr } = useQuery<AirDropEvent[]>(
    ["RecordAirDrop", `AirDrop_${tokenType}`],
    () =>
      client("AirDrop", {
        filter: {
          token: tokenAddr.toLowerCase() || "",
          user: address || "",
        },
        fromBlock: 10000000,
        toBlock: "latest",
      }),
    {
      enabled: !!address && !!tokenAddr,
    }
  );

  const blockInfo = useQueries(
    airdropArr
      ? airdropArr.map((eve: { blockNumber: number }) => {
          return {
            queryKey: ["user", eve.blockNumber],
            queryFn: () => wanWeb3?.eth.getBlock(eve.blockNumber),
            enabled: !!airdropArr.length && !!wanWeb3,
          };
        })
      : []
  );

  return useMemo(() => {
    if (blockInfo.length && blockInfo.every((item) => !item.isLoading)) {
      const bInfo = {};
      blockInfo.forEach((item) => {
        if (typeof item.data === "object") {
          // @ts-ignore
          bInfo[item.data.number] = item.data.timestamp;
        }
      });
      airdropArr?.forEach(
        // @ts-ignore
        (item) => (item.returnValues.timestamp = bInfo[item.blockNumber])
      );

      airdropArr?.forEach(
        (item) =>
          // @ts-ignore
          (item.returnValues.formatAmount = fromWei(item.returnValues.amount))
      );
      return {
        airdropArr: airdropArr?.sort(
          // @ts-ignore
          (a, b) => b.returnValues.timestamp - a.returnValues.timestamp
        ),
      };
    } else {
      return {};
    }
  }, [blockInfo, airdropArr]);
};

export const useQueryWanDailyIncentive = (tokenType: TokenType) => {
  const { address, networkId } = useWallet();
  const client = useRecordAirDropFunc();
  const tokenAddr = useMemo(
    () =>
      address && config[networkId || ""]
        ? config[networkId || ""].tokenAddrArr[tokenType]
        : "",
    [address, networkId, tokenType]
  );
  const { data, ...restData } = useQuery(
    ["RecordAirDrop", `dailyIncentive_${tokenType}`],
    () => client("dailyIncentive", [tokenAddr]),
    {
      enabled: !!tokenAddr,
    }
  );
  return {
    data: formatValue(data * 7, "WAN"),
    ...restData,
  };
};

export const useQueryTokenAllowance = (tokenType: TokenType) => {
  const { address } = useWallet();
  const contractConfig = useConfig("MultiCoinStakeDelegate");

  const [contractInstance] = useErc20Contract(tokenType);
  return useQuery(
    ["ERC20", `allowance_${tokenType}`],
    () =>
      tokenType !== "WAN"
        ? contractInstance.methods
            .allowance(address, contractConfig?.addr)
            .call()
        : Promise.resolve("0"),
    { enabled: !!address && !!contractConfig }
  );
};

export const useMutateSendTx = (type: string) => {
  const queryClient = useQueryClient();
  const { web3 } = useWallet();

  return useMutation(
    (txData: Partial<TransParams>) =>
      web3.eth
        .sendTransaction(txData)
        .on("transactionHash", (txHash: string) => txHash)
        .on("error", (err: any) => {
          console.error("web3Wallet sendTransaction error: %O", err);
          return err;
        }),
    {
      onSuccess: (txhash) => {
        queryClient.invalidateQueries([
          "MultiCoinStakeDelegate",
          `stakers_${type}`,
        ]);
      },
    }
  );
};

export const useQueryTransactionReceipt = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [txReceipt, setTxReceipt] = useState<object | string | undefined>(
    undefined
  );
  const queryClient = useQueryClient();

  const fetchReceipt = useCallback(
    (txhash: string, cb: (params: ReceiptResult) => void) => {
      setIsLoading(true);
      const timer = setInterval(
        () =>
          callMetamaskFunc("eth_getTransactionReceipt", (i: any) => i, [txhash])
            .then((ret) => {
              if (ret) {
                setTxReceipt(ret);
                setIsSuccess(true);
                setIsLoading(false);
                clearInterval(timer);
                queryClient.invalidateQueries();
                cb({ txReceipt: ret, isReceiptSuccess: true });
              }
            })
            .catch((error) => {
              console.log("useQueryTransactionReceipt", error);
              setTxReceipt("0x");
              setIsLoading(false);
              clearInterval(timer);
              cb({ txReceipt: "0x", isReceiptSuccess: false, error });
            }),
        2000
      );
    },
    [queryClient]
  );

  return {
    txReceipt,
    isLoading,
    isSuccess,
    fetchReceipt,
  };
};

export const useSmgInfo = () => {
  const { data: smgId } = useQueryCurrentStoremanGroupID();
  const client = useStoremanGroupDelegateFunc();

  return useQuery(
    ["StoremanGroupDelegate", "getStoremanGroupInfo"],
    () => client("getStoremanGroupInfo", [smgId]),
    {
      enabled: !!smgId,
      staleTime: Infinity,
    }
  );
};

export const useQueryPosStakeInfo = () => {
  const client = useHttp();
  return useQuery(["usePosStakeInfo"], () =>
    client({
      url: `${WANSCAN_API}/vlds_json`,
      strict: true,
    })
  );
};

export const useQueryStoremanInfo = (): Array<StoremanInfoType> | [] => {
  const client = useHttp();
  const storemanGroupList = useIwan("getStoremanGroupList", {}, true);

  const groupIdArr1 = useMemo(() => {
    return storemanGroupList
      ? storemanGroupList
          .filter((i: { status: string }) => Number(i.status) >= 4)
          .map((v: { groupId: string }) => v.groupId)
      : [];
  }, [storemanGroupList]);

  const groupIdArr2 = useMemo(() => {
    return storemanGroupList
      ? storemanGroupList
          .filter((i: { status: string }) => Number(i.status) < 4)
          .map((v: { groupId: string }) => v.groupId)
      : [];
  }, [storemanGroupList]);

  const groupMember1 = useIwan(
    "getStoremanGroupMember",
    { groupId: groupIdArr1 },
    !!groupIdArr1.length
  );

  const groupMember2 = useIwan(
    "getStoremanCandidates",
    { groupId: groupIdArr2 },
    !!groupIdArr2.length
  );

  const groupMemberArr = useMemo(() => {
    if (groupMember1 || groupMember2) {
      const temp = _.unionBy<StoremanInfoType>(
        (groupMember1 || []).concat(groupMember2 || []).flat(),
        "wkAddr"
      );
      return temp.filter((v) => {
        let ret = storemanGroupList.find((j: { groupId: string }) =>
          [v.groupId, v.nextGroupId].includes(j.groupId)
        );
        return !v.isWhite && !!ret;
      });
    } else {
      return [];
    }
  }, [groupMember1, groupMember2, storemanGroupList]);

  const { data: storemanInfo } = useQuery(["useStoremanInfo"], () =>
    client({
      url: `${WANSCAN_API}/storemanlist_json`,
      strict: true,
    })
  );

  return useMemo(() => {
    if (
      storemanInfo &&
      storemanInfo.length &&
      groupMemberArr &&
      groupMemberArr.length
    ) {
      const ret: any[] = [];
      storemanInfo.forEach((j: StoremanInfoType) => {
        const tmp = groupMemberArr.find(
          (v) =>
            v.groupId === j.groupId &&
            v.wkAddr.toLowerCase() === j.wkAddr.toLowerCase()
        );
        if (tmp) {
          tmp.activity = j.activity;
          tmp.name = j.name;
          tmp.imgdata = j.imgdata;
          tmp.totalDeposit = j.totalDeposit;
          tmp.totalDepositRaw = j.totalDepositRaw;
          tmp.selfDeposit = j.selfDeposit;
          tmp.delegateFee = j.delegateFee;
          tmp.powerWeight = j.powerWeight;
          tmp.status = j.status;
          tmp.startTime = j.startTime;
          tmp.endTime = j.endTime;
          tmp.autoRenew = j.autoRenew;
          ret.push(tmp);
        }
      });
      return ret;
    } else {
      return [];
    }
  }, [storemanInfo, groupMemberArr]);
};

export const useQueryStatsInfo = () => {
  const client = useHttp();
  return useQuery(["useStatsInfo"], () =>
    client({
      url: `${WANSCAN_API}/apiInfo?type=stats`,
      strict: true,
    })
  );
};

export const useQueryStoremanConf = () => {
  const storemanConf = useIwan("getStoremanConf", {}, true);
  return useMemo(() => {
    return storemanConf;
  }, [storemanConf]);
};
