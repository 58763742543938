import styled from "@emotion/styled";

export default function PageFooter() {
  return (
    <Container>
      For a comprehensive understanding, visit the{" "}
      <AStyled
        href="https://www.wanchain.org/technology"
        target="_blank"
        rel="noopener noreferrer"
      >
        Technology
      </AStyled>{" "}
      section on the Wanchain website.
    </Container>
  );
}

const Container = styled.div`
  font-size: 16px;
  color: #a9a9a9;
  font-weight: 400;
  text-align: center;
`;

const AStyled = styled.a`
  cursor: pointer;
  color: #14daca;
  &:hover {
    color: #14daca;
  }
`;
