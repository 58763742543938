import { ReactNode } from "react";
import { WalletProvider } from "context/wallet-context";
import { LocalStorageProvider } from "context/localstorage-context";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

export const AppProviders = ({ children }: { children: ReactNode }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <LocalStorageProvider>
          <WalletProvider>{children}</WalletProvider>
        </LocalStorageProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  );
};
