import styled from "@emotion/styled";
import { Button } from "antd";
import { useWallet } from "context/wallet-context";
import { shorthandString } from "utils/index";
import { ReactComponent as WalletIcon } from "assets/wallet-icon.svg";
import { useMemo } from "react";
import { AddEthereumChainParameter } from "types/wallet-type";
import { TESTNET_INFO, MAINNET_INFO } from "config";

const CHAINID = Number(process.env.REACT_APP_CHAINID);

export const ConnectWallet = () => {
  const wallet = useWallet();
  const { address, connected, connect, resetApp, getLogo, networkId, oneid } =
    wallet;

  const handleConnectWallet = async () => {
    try {
      if (!connected) {
        await connect();
      } else {
        if (networkId !== CHAINID) {
          if (window.ethereum && Number(networkId) !== CHAINID) {
            const params: AddEthereumChainParameter = (
              process.env.REACT_APP_CHAINID === "888"
                ? MAINNET_INFO
                : TESTNET_INFO
            ) as AddEthereumChainParameter;

            (window.ethereum as any)
              .request({
                method: "wallet_addEthereumChain",
                params: [params],
              })
              .then((result: any) => {
                console.debug(result);
              })
              .catch((error: any) => {
                console.debug(error);
              });
          }
        } else {
          await resetApp();
          await connect();
        }
      }
    } catch (error) {
      console.log("handleConnectWallet: ", error);
    }
  };

  const BtnColor = useMemo(() => {
    if (connected && networkId === CHAINID) {
      return "#0F68AA";
    } else if (connected && networkId !== CHAINID) {
      return "red";
    } else {
      return "#0F68AA";
    }
  }, [connected, networkId]);

  return (
    <Container>
      <ConnectBtn
        onClick={handleConnectWallet}
        icon={
          connected ? (
            networkId === CHAINID ? (
              <WalletIconStyle width={22} src={getLogo()} alt="wallet_icon" />
            ) : null
          ) : (
            <WalletIcon />
          )
        }
        size={"large"}
        bgc={BtnColor}
      >
        {connected
          ? networkId === CHAINID
            ? oneid !== ""
              ? oneid
              : shorthandString(address)
            : "Wrong Network"
          : "Connect Wallet"}
      </ConnectBtn>
    </Container>
  );
};

const Container = styled.div`
  margin-right: 2rem;
`;

const ConnectBtn = styled(Button)<{
  bgc?: string;
}>`
  color: #fff;
  height: 30px;
  font-size: 16px;
  width: 18rem;
  border-radius: 18px;
  font-family: Inter-Regular, Inter;
  border: none;
  position: relative;
  line-height: 22px;
  background: ${(props) => props.bgc};
  &:active,
  &:focus,
  &:hover {
    color: #fff;
    background: ${(props) => props.bgc};
  }
  .anticon {
    vertical-align: -0.3rem;
  }

  .anticon svg {
    position: relative;
    top: -4px;
  }
`;

const WalletIconStyle = styled.img`
  margin-right: 1rem;
  display: inline-block;
  position: relative;
  top: -3px;
`;
