import BigNumber from "bignumber.js";
import { useWallet } from "context/wallet-context";
import { useMemo } from "react";
import {
  DelegatorTotalIncentiveType,
  StoremanDelegatorInfoType,
} from "types/storeman-type";
import { useIwan } from "utils/useWebSocket";

export const useStoremanGroupInfo = (groupId: string | undefined) => {
  const data = useIwan("getStoremanGroupInfo", { groupId }, !!groupId);
  return useMemo(() => {
    if (data) {
      return data;
    } else {
      return null;
    }
  }, [data]);
};

export const useStoremanDelegatorInfo = () => {
  const { address } = useWallet();
  const data = useIwan("getStoremanDelegatorInfo", { address }, !!address);
  return useMemo(() => {
    if (data) {
      data.forEach((v: StoremanDelegatorInfoType) => {
        v.deposit = new BigNumber(v.deposit).dividedBy(
          Math.pow(10, 18).toString(10)
        );
        v.incentive = new BigNumber(v.incentive).dividedBy(
          Math.pow(10, 18).toString(10)
        );
      });
      return data;
    } else {
      return [];
    }
  }, [data]);
};

export const useDelegatorTotalIncentive = () => {
  const { address } = useWallet();
  const data = useIwan(
    "getDelegatorTotalIncentive",
    { chainType: "WAN", address },
    !!address
  );
  return useMemo(() => {
    if (data) {
      data.forEach(
        (v: DelegatorTotalIncentiveType) =>
          (v.amount = new BigNumber(v.amount).dividedBy(
            Math.pow(10, 18).toString(10)
          ))
      );
      return data;
    } else {
      return [];
    }
  }, [data]);
};
