import styled from "@emotion/styled";
import { Switch } from "antd";
import Dark1 from "assets/dark-btn-1.png";
import Light1 from "assets/light-btn-1.png";
import Dark0 from "assets/dark-btn-0.png";
import Light0 from "assets/light-btn-0.png";
import { useState } from "react";
import { useLocalStorage } from "context/localstorage-context";
import { useMount } from "utils";

const CloseOutlined = () => <img src={Light1} alt="darktheme" width={20} />;

const CheckOutlined = () => <img src={Dark0} alt="lighttheme" width={20} />;

export default function ThemeSwitch({ inSiderbar }: { inSiderbar: boolean }) {
  const [isDark, setIsDark] = useState(false);
  const { setTheme } = useLocalStorage();

  useMount(() => setIsDark(window.localStorage.theme === "dark"));

  const handleToggleTheme = (checked: boolean) => {
    setIsDark(checked);
    if (checked) {
      setTheme("dark");
      document.documentElement.classList.add("dark");
      window.localStorage.theme = "dark";
    } else {
      setTheme("light");
      document.documentElement.classList.remove("dark");
      window.localStorage.theme = "";
    }
  };

  return (
    <Container
      theme={isDark ? Dark1 : Light0}
      isDark={isDark}
      inSiderbar={inSiderbar}
    >
      <Switch
        className="bg-switchBtn-0 dark:bg-switchBtn-1"
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={isDark}
        onClick={handleToggleTheme}
      />
    </Container>
  );
}

const Container = styled.div<{
  theme: string;
  isDark: boolean;
  inSiderbar: boolean;
}>`
  margin-right: 1rem;

  @media (max-width: 768px) {
    display: ${(props) => (props.inSiderbar ? "initial" : "none")};
  }

  .ant-switch {
    height: 30px;
    min-width: ${(props) => (props.inSiderbar ? "170px" : "70px")};
    background-color: ${(props) => (props.isDark ? "#133E5B" : "#fff")};
  }

  .ant-switch-checked .ant-switch-handle {
    left: ${(props) =>
      props.inSiderbar ? "calc(100% - 90px - 2px)" : "calc(100% - 30px - 2px)"};
  }

  .ant-switch-handle {
    width: ${(props) => (props.inSiderbar ? "100px" : "30px")};
    height: 30px;
    top: 0;
    left: 0;
  }

  .ant-switch-handle::before {
    background-image: url(${(props) => props.theme});
    background-color: #0f68aa;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 15px;
  }

  .ant-switch-checked .ant-switch-inner {
    margin: ${(props) =>
      props.inSiderbar ? "-4px 70px 0 0" : "-4px 30px 0 0"};
    height: 30px;
    line-height: 30px;
  }

  .ant-switch-checked:focus {
    box-shadow: none;
  }
  .ant-switch-inner {
    margin: ${(props) =>
      props.inSiderbar ? "-2px 0 0 100px" : "-2px 0 0 35px"};
  }
  .ant-switch:focus {
    box-shadow: none;
  }
`;
