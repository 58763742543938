import { useMount } from "utils";
import React, { ReactNode, useState } from "react";

const LocalStorageContext = React.createContext<
  | {
      theme: string;
      setTheme: (theme: THEME_INITIAL_STATE_TYPE) => void;
      bridgeSwitchOn: boolean;
      setBridgeSwitchOn: (theme: SWITCH_TYPE) => void;
      posSwitchOn: boolean;
      setPosSwitchOn: (theme: SWITCH_TYPE) => void;
    }
  | undefined
>(undefined);
LocalStorageContext.displayName = "LocalStorageContext";

type THEME_INITIAL_STATE_TYPE = "dark" | "light";

type SWITCH_TYPE = true | false;

const THEME_INITIAL_STATE = "light";

export const LocalStorageProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] =
    useState<THEME_INITIAL_STATE_TYPE>(THEME_INITIAL_STATE);

  const [bridgeSwitchOn, setBridgeSwitchOn] = useState<SWITCH_TYPE>(false);

  const [posSwitchOn, setPosSwitchOn] = useState<SWITCH_TYPE>(false);

  useMount(() => {
    if (!!window.localStorage.theme) {
      setTheme(window.localStorage.theme);
    } else {
      setTheme(theme);
    }
    if (window.localStorage.bridgeSwitchOn === undefined) {
      setBridgeSwitchOn(bridgeSwitchOn);
    } else {
      setBridgeSwitchOn(window.localStorage.bridgeSwitchOn === "true");
    }
    if (window.localStorage.posSwitchOn === undefined) {
      setPosSwitchOn(posSwitchOn);
    } else {
      setPosSwitchOn(window.localStorage.posSwitchOn === "true");
    }
  });

  return (
    <LocalStorageContext.Provider
      children={children}
      value={{
        theme,
        setTheme,
        bridgeSwitchOn,
        setBridgeSwitchOn,
        posSwitchOn,
        setPosSwitchOn,
      }}
    />
  );
};

export const useLocalStorage = () => {
  const context = React.useContext(LocalStorageContext);
  if (!context) {
    throw new Error("useLocalStorage必须在WalletProvider中使用");
  }
  return context;
};
