import { useEffect, useRef, useState } from "react";
import BigNumber from "bignumber.js";
import Numeral from "numeral";
import { TokenType } from "types/token-type";
import { getDecimal } from "config";
import { invalidAmount } from "types";
import { ActionType } from "types/action-type";
import Web3 from "web3";

const web3 = new Web3();

export function fromWei1(data: any, unit = "ether") {
  // @ts-ignore
  return web3.utils.fromWei(data.toString(), unit);
}

export const resetRoute = () => (window.location.href = window.location.origin);

export const isFalsy = (value: unknown) => (value === 0 ? false : !value);

export const isVoid = (value: unknown) =>
  value === undefined || value === null || value === "";

export const cleanObject = (object?: { [key: string]: unknown }) => {
  if (!object) {
    return {};
  }
  const result = { ...object };
  Object.keys(result).forEach((key) => {
    const value = result[key];
    if (isVoid(value)) {
      delete result[key];
    }
  });
  return result;
};

export const subset = <
  O extends { [key in string]: unknown },
  K extends keyof O
>(
  obj: O,
  keys: K[]
) => {
  const filteredEntries = Object.entries(obj).filter(([key]) =>
    keys.includes(key as K)
  );
  return Object.fromEntries(filteredEntries) as Pick<O, K>;
};

export const isInValidAmount = (value: invalidAmount) =>
  [null, undefined, "0", 0].includes(value);

export const useMount = (callback: () => void) => {
  useEffect(() => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/**
 * 返回组件的挂载状态，如果还没挂载或者已经卸载，返回false；反之，返回true
 */
export const useMountedRef = () => {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  return mountedRef;
};

export const shorthandString = (str: string, len?: number): string => {
  let tmp = len || 8;
  return str.substr(0, tmp / 2 + 2) + "...." + str.substr(-tmp / 2);
};

export const formatNumBetweenZeroAndOne = (str: string): string => {
  return Number(str).toFixed(6).toString();
};

// eg. 1.03000 ==> 1.03
export const removeExtraZero = (str: string): string => {
  let decimalPlace = parseFloat("." + str.split(".")[1]);
  if (isNaN(decimalPlace)) return str;
  return decimalPlace === 0
    ? str.split(".")[0]
    : str.split(".")[0] + "." + decimalPlace.toString().split(".")[1];
};

export const toHexParams = (str: string, decimals?: number): string => {
  return (
    "0x" +
    new BigNumber(str)
      .multipliedBy(Math.pow(10, decimals || decimals === 0 ? decimals : 18))
      .toString(16)
  );
};

export const isValidAmount = (data: string | number | null | undefined) => {
  if (data) {
    return new BigNumber(data).gt("0");
  }
  return false;
};

export const fromWei = (data: string | number | null, decimal?: number) => {
  if (!data) {
    return "0";
  }
  let ret = new BigNumber(data).dividedBy(Math.pow(10, 18)).toString(10);
  return removeExtraZero(
    Numeral(Number(ret).toFixed(decimal || 6)).format("0,0.000")
  );
};

export const formatValue = (
  value: string | number | undefined,
  tokenType?: TokenType | "WAN"
): string => {
  if (!value) {
    return "0";
  }
  const decimals = tokenType ? getDecimal.get(tokenType) || 0 : 0;
  const formatNum = new BigNumber(value)
    .dividedBy(Math.pow(10, decimals))
    .toString(10);
  return removeExtraZero(Numeral(formatNum).format("0,0"));
};

export const toApproveBigNum = (tokenType: TokenType) => {
  const decimals = tokenType ? getDecimal.get(tokenType) || 0 : 0;
  return (
    "0x" +
    new BigNumber("2", 10)
      .pow(255)
      .minus(1)
      .dividedBy("1e" + decimals)
      .integerValue()
      .toString(16)
  );
};

export const formatBalance = (
  balance: string,
  decimal: number | string = 18
): string => {
  return new BigNumber(balance).dividedBy(Math.pow(10, +decimal)).toString(10);
};

export const useLoading = () => {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => setIsLoading(false), 2000);

  return { isLoading };
};

export const validGroupId = (id: string) =>
  id !== "0x0000000000000000000000000000000000000000000000000000000000000000" &&
  !!id;

interface isValidInputAmountProps {
  balance: string;
  amount: string;
  action: ActionType;
  tokenType: TokenType;
}
interface isValidInputAmountResult {
  error: boolean;
  message?: string;
}
export const isValidInputAmount = (
  params: isValidInputAmountProps
): isValidInputAmountResult => {
  const { balance, amount, action, tokenType } = params;

  switch (action) {
    case "Unstake":
    case "Withdraw":
      if (tokenType === "WAN" && new BigNumber(balance).eq("0")) {
        return {
          error: true,
          message: "Please make sure there is sufficient gas fee",
        };
      } else {
        return { error: false };
      }
    case "Deposit":
      const ruleOne =
        isNaN(Number(amount)) ||
        (new BigNumber(amount).gte(balance) && tokenType === "WAN");
      const ruleTwo =
        isNaN(Number(amount)) ||
        (new BigNumber(amount).gt(balance) && tokenType !== "WAN");
      const ruleThree = new BigNumber(amount).lte("0");
      const ruleFour = new BigNumber(amount)
        .multipliedBy(Math.pow(10, getDecimal.get(tokenType) || 0))
        .isInteger();

      if (ruleOne || ruleTwo || ruleThree || !ruleFour) {
        return { error: true, message: "Please enter a valid amount" };
      } else {
        return { error: false };
      }
    default:
      return { error: false };
  }
};

export const cutString = (str: string, len: number): string => {
  if (str.length * 2 <= len) {
    return str;
  }
  var strlen = 0;
  var s = "";
  for (var i = 0; i < str.length; i++) {
    s = s + str.charAt(i);
    if (str.charCodeAt(i) > 128) {
      strlen = strlen + 2;
      if (strlen >= len) {
        return (
          s.substring(0, s.length - 1) + "..." + str.substring(str.length - 4)
        );
      }
    } else {
      strlen = strlen + 1;
      if (strlen >= len) {
        return (
          s.substring(0, s.length - 2) + "..." + str.substring(str.length - 4)
        );
      }
    }
  }
  return s;
};

const formatHMS1 = (cur: Date) => {
  var h = cur.getUTCHours();
  var m: number | string = cur.getMinutes();
  if (m < 10) m = "0" + m;
  var s: number | string = cur.getSeconds();
  if (s < 10) s = "0" + s;
  if (h < 10) {
    return "0" + h + ":" + m;
  } else {
    return h + ":" + m;
  }
};

export const getUTCV2 = (time: number, onlyDate = false) => {
  let current = new Date(time * 1000);
  let mon = current.getUTCMonth() + 1;
  let date = current.getUTCDate();
  let year = current.getUTCFullYear();
  let hms = formatHMS1(current);
  if (onlyDate) {
    return `${year}-${mon}-${date}`;
  } else {
    return `${year}-${mon}-${date} ${hms}`;
  }
};

export const hexCharCodeToStr = (str: string) => {
  str = str.trim().replace(/^0x/g, "");
  if (str.length % 2 !== 0) {
    return "";
  }
  let tempstr = "";
  try {
    for (var b = 0; b < str.length; b = b + 2) {
      if (str.substring(b, b + 2) === "00") {
        continue;
      }
      tempstr =
        tempstr + String.fromCharCode(parseInt(str.substring(b, b + 2), 16));
    }
  } catch (err) {
    tempstr = decodeURIComponent(
      str.replace(/\s+/g, "").replace(/[0-9a-f]{2}/g, "%$&")
    );
  }
  return tempstr;
};

export const checkAmountUnit = function (amount: string) {
  if (!Number.isInteger(Number(18))) {
    throw new Error("Decimals must be a integer");
  }
  if (amount === "0") {
    return true;
  }
  let amountStr = new BigNumber(amount).toString(10);
  let decimalLen = amountStr.includes(".")
    ? amountStr.length - amountStr.indexOf(".") - 1
    : 0;
  return !!new BigNumber(amount).gte(1 / 10 ** 18) && decimalLen <= 18;
};
