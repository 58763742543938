import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import styled from "@emotion/styled";
import LOGO from "assets/logo.png";
import LOGO_LIGHT from "assets/logo_light.png";
import Home from "assets/Home.png";
import Support from "assets/Support.png";
import PosAside from "assets/posAside.png";
import CMC from "assets/CMC.png";
import Github from "assets/Github.png";
import Medium from "assets/Medium.png";
import Telegram from "assets/Telegram.png";
import Twitter from "assets/Twitter.png";
import Bridge from "assets/Bridge.png";
import Docs from "assets/Docs.png";
import Explorer from "assets/Explorer.png";
import Website from "assets/Website.png";
import BridgeAside from "assets/BridgeAside.png";
import Ecosystem from "assets/Ecosystem.png";
import { resetRoute } from "utils";
import { ButtonNoPadding } from "components/lib";
import { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useLocalStorage } from "context/localstorage-context";
import ThemeSwitch from "components/theme-switch";

const SIDEBAR_ITEM = {
  "/": "1",
  "/pos": "2",
  "/bridge": "3",
};

const Aside = ({
  toggled,
  handleToggleSidebar,
}: {
  toggled: boolean;
  handleToggleSidebar: (value: boolean) => void;
}) => {
  const [item, setItem] = useState(SIDEBAR_ITEM["/"]);
  const selectItem = (key: string) => setItem(key);
  const { pathname } = useLocation();
  const { theme } = useLocalStorage();

  useEffect(() => {
    let item;
    // @ts-ignore
    if (SIDEBAR_ITEM[pathname]) {
      // @ts-ignore
      item = SIDEBAR_ITEM[pathname];
    } else {
      if (pathname.startsWith("/posdetail/")) {
        item = "2";
      } else if (pathname.startsWith("/storemandetail/")) {
        item = "3";
      }
    }
    selectItem(item);
  }, [pathname]);

  const isdark = useMemo(() => theme === "dark", [theme]);

  return (
    <ProSidebarCus
      toggled={toggled}
      breakPoint="md"
      isdark={isdark}
      onToggle={handleToggleSidebar}
      className="bg-common-0 dark:bg-common-1"
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <ButtonNoPadding type={"link"} onClick={resetRoute}>
            <img src={isdark ? LOGO : LOGO_LIGHT} alt="logo" width="170px" />
          </ButtonNoPadding>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItemCus
            icon={<Img src={Home} alt="Home" />}
            onClick={() => selectItem(SIDEBAR_ITEM["/"])}
            active={item === "1"}
            isdark={isdark}
          >
            <NavLink to={"/"}>Home</NavLink>
          </MenuItemCus>
          <MenuItemCus
            icon={<Img src={PosAside} alt="PosAside" />}
            onClick={() => selectItem(SIDEBAR_ITEM["/pos"])}
            active={item === "2"}
            isdark={isdark}
          >
            <NavLink to={"/pos"}>Delegate to PoS</NavLink>
          </MenuItemCus>
          <MenuItemCus
            icon={<Img src={Bridge} alt="Bridge" />}
            onClick={() => selectItem(SIDEBAR_ITEM["/bridge"])}
            active={item === "3"}
            isdark={isdark}
          >
            <NavLink to={"/bridge"}>Delegate to Bridge</NavLink>
          </MenuItemCus>
        </Menu>

        <LineStyle isdark={isdark} />

        <Menu iconShape="circle">
          <MenuItemCus
            icon={<Img src={Support} alt="Support" />}
            active={false}
            isdark={isdark}
          >
            <SidebarBtn
              isdark={isdark}
              href="mailto:techsupport@wanchain.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ justifyContent: "start" }}
            >
              Support
            </SidebarBtn>
          </MenuItemCus>
          <MenuItemCus
            className="text-black dark:text-white"
            icon={<Img src={Docs} alt="docs" />}
            active={false}
            isdark={isdark}
          >
            <SidebarBtn
              isdark={isdark}
              href="https://docs.wanchain.org/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ justifyContent: "start" }}
            >
              Documents
            </SidebarBtn>
          </MenuItemCus>
        </Menu>

        <LineStyle isdark={isdark} />

        <Menu iconShape="circle">
          <MenuItemCus
            className="text-black dark:text-white"
            icon={<Img src={Explorer} alt="Explorer" />}
            active={false}
            isdark={isdark}
          >
            <SidebarBtn
              isdark={isdark}
              href="https://www.wanscan.org/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ justifyContent: "start" }}
            >
              Explorer
            </SidebarBtn>
          </MenuItemCus>
          <MenuItemCus
            icon={<Img src={Website} alt="Website" />}
            active={false}
            isdark={isdark}
          >
            <SidebarBtn
              isdark={isdark}
              href="https://www.wanchain.org/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ justifyContent: "start" }}
            >
              Website
            </SidebarBtn>
          </MenuItemCus>
          <MenuItemCus
            icon={<Img src={BridgeAside} alt="BridgeAside" />}
            onClick={() => selectItem("5")}
            active={false}
            isdark={isdark}
          >
            <SidebarBtn
              isdark={isdark}
              href="https://bridge.wanchain.org/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ justifyContent: "start" }}
            >
              Bridge
            </SidebarBtn>
          </MenuItemCus>
          <MenuItemCus
            icon={<Img src={Ecosystem} alt="Ecosystem" />}
            active={false}
            isdark={isdark}
          >
            <SidebarBtn
              isdark={isdark}
              href="https://www.wanchain.org/dapps"
              target="_blank"
              rel="noopener noreferrer"
              style={{ justifyContent: "start" }}
            >
              Ecosystem
            </SidebarBtn>
          </MenuItemCus>
        </Menu>
      </SidebarContent>

      <SidebarFooterCus style={{ textAlign: "center" }}>
        <Div1>
          <ThemeSwitch inSiderbar={true} />
        </Div1>
        <ThirdPartyCon>
          <SidebarBtn
            href="https://twitter.com/wanchain_org"
            target="_blank"
            rel="noopener noreferrer"
            isdark={isdark}
          >
            <Img2 src={Twitter} alt="Twitter" />
          </SidebarBtn>
          <SidebarBtn
            href="https://t.me/WanchainCHAT"
            target="_blank"
            rel="noopener noreferrer"
            isdark={isdark}
          >
            <Img2 src={Telegram} alt="Telegram" />
          </SidebarBtn>
          <SidebarBtn
            href="https://medium.com/wanchain-foundation"
            target="_blank"
            rel="noopener noreferrer"
            isdark={isdark}
          >
            <Img2 src={Medium} alt="Medium" />
          </SidebarBtn>
          <SidebarBtn
            href="https://github.com/wanchain"
            target="_blank"
            rel="noopener noreferrer"
            isdark={isdark}
          >
            <Img2 src={Github} alt="Github" />
          </SidebarBtn>
          <SidebarBtn
            href="https://coinmarketcap.com/currencies/wanchain/"
            target="_blank"
            rel="noopener noreferrer"
            isdark={isdark}
          >
            <Img2 src={CMC} alt="CMC" />
          </SidebarBtn>
        </ThirdPartyCon>
      </SidebarFooterCus>
    </ProSidebarCus>
  );
};

export default Aside;

const SidebarBtn = styled.a<{
  isdark: boolean;
}>`
  display: inline-block;
  transition: width 0.3s;
  color: ${(props) => (props.isdark ? "#fff" : "#000")};
  text-decoration: none;
  margin: 0 auto;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  span {
    margin-left: 5px;
    font-size: 13px;
  }
  &:hover {
    color: ${(props) => (props.isdark ? "#fff" : "#000")};
  }
`;

const ProSidebarCus = styled(ProSidebar)<{
  isdark: boolean;
}>`
  height: 100%;

  .pro-sidebar-inner {
    background: ${(props) => (props.isdark ? "rgba(0, 0, 0, 0.05)" : "#fff")};
    backdrop-filter: blur(50px);
  }
`;

const MenuItemCus = styled(MenuItem)<{
  active?: boolean;
  isdark: boolean;
}>`
  .pro-inner-item {
    margin: 8px 8px 8px 16px;
    border-radius: 5px;
    background: ${(props) =>
      props.active ? "rgba(15,104,170,0.2)" : "initial"};
  }
  .pro-inner-item:hover {
    background-color: rgba(15, 104, 170, 0.2);
  }
  .pro-item-content {
    color: ${(props) => (props.isdark ? "#fff" : "#333")};
  }
  .pro-icon-wrapper {
    background-color: initial !important;
    color: ${(props) => (props.isdark ? "#fff" : "#333")};
    margin-right: 0;
  }
  a {
    color: ${(props) => (props.isdark ? "#fff!important" : "#333!important")};
  }
`;

const SidebarFooterCus = styled(SidebarFooter)`
  border-top: none !important;
  margin: 10px 5%;
`;

const ThirdPartyCon = styled.div`
  display: flex;
  padding: 0 10%;
  width: 100%;
  justify-content: space-around;
`;

const Div1 = styled.div`
  width: 100%;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Img = styled.img`
  width: 20px;
`;

const Img2 = styled.img`
  width: 18px;
`;

const LineStyle = styled.div<{
  isdark: boolean;
}>`
  border-bottom: ${(props) =>
    props.isdark ? "1px solid #273A48" : "1px solid #EBEFF2"};
  width: 100%;
`;
