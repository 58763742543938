import styled from "@emotion/styled";
import Arrow from "assets/Arrow.png";
import WAN from "assets/WAN.png";
import { Progress } from "antd";
import { Link } from "react-router-dom";
import { cutString } from "utils";
import Numeral from "numeral";
import BigNumber from "bignumber.js";
import { useMemo } from "react";
import { useLocalStorage } from "context/localstorage-context";

const CardContent = ({ storeman }: { storeman: any }) => {
  const quota = useMemo(() => {
    if (storeman.quota) {
      const quotaValue = Numeral(storeman.quota).value() || 0;
      const tmp = new BigNumber(quotaValue).gt("0") ? quotaValue : "0";
      return Numeral(tmp).format("0,0");
    } else {
      return storeman.quota;
    }
  }, [storeman.quota]);

  return (
    <CardContentCon>
      <CardPart1>
        <span style={{ fontSize: "12px", color: "#BFBFBF" }}>STAKE</span>
        <StakeData1 className="text-light-0 dark:text-white">
          {storeman.totalDeposit || "--/--"}
        </StakeData1>
      </CardPart1>
      <CardPart2>
        <span
          className="text-light-111 dark:text-light-222"
          style={{ fontSize: "18px" }}
        >
          {quota || "--/--"}
        </span>
        <StakeData2>QUOTA</StakeData2>
      </CardPart2>
    </CardContentCon>
  );
};

export default function CardPart({ storeman }: { storeman: any }) {
  const { theme } = useLocalStorage();
  const isdark = useMemo(() => theme === "dark", [theme]);

  const quota = useMemo(() => {
    const quotaValue = Numeral(storeman.quota).value() || 0;
    return new BigNumber(quotaValue).gt("0") ? quotaValue : "0";
  }, [storeman.quota]);

  const percent = useMemo(() => {
    const selfDeposit = Numeral(storeman.selfDeposit).value() || 1;
    const per = new BigNumber(quota)
      .dividedBy(selfDeposit * 10)
      .multipliedBy(100)
      .toString(10);
    return Number(Number(per).toFixed(0));
  }, [quota, storeman.selfDeposit]);

  return (
    <Container isdark={isdark}>
      <Link to={`/storemandetail/${storeman.wkAddr}`}>
        <Card className="card bg-white dark:bg-dark-222">
          <CardHeader>
            <div style={{ textAlign: "left" }}>
              <IconContainer>
                <Icon src={storeman.imgdata} alt={storeman.name} />
              </IconContainer>
              <StoremanName className="text-light-0 dark:text-white">
                {storeman.name.startsWith("0x")
                  ? cutString(storeman.name, 8)
                  : storeman.name}
              </StoremanName>
            </div>
            <Link to={`/storemandetail/${storeman.wkAddr}`}>
              <ArrowStyle className="arrowStyle" src={Arrow} alt="arrow" />
            </Link>
          </CardHeader>
          <Progress
            width={160}
            type="circle"
            trailColor={isdark ? "#135C8F" : "#F2F2F2"}
            strokeWidth={5}
            strokeColor={isdark ? "#112A3B" : "#D7DEE3"}
            percent={percent}
            format={(percent) => <CardContent storeman={storeman} />}
          />
          <div style={{ height: "40px" }}>
            <BottomArea>
              <HeartPercent>
                <Percent>{storeman.activity}</Percent>
                <TextIcon>Activity</TextIcon>
              </HeartPercent>
              <FeeRate>
                <Percent style={{ color: "#2FBDF4" }}>
                  {storeman.delegateFee / 100 + "%"}
                </Percent>
                <TextIcon>Fee Rate</TextIcon>
              </FeeRate>
            </BottomArea>
          </div>
        </Card>
        <MyCard className="mycard bg-light-666 dark:bg-dark-555">
          <Data>
            <span>My Delegations</span>
            <span className="text-light-0 dark:text-white">
              {Numeral(storeman.myDeposit)
                .format("0,0.00")
                .replace(/\.?0+$/, "")}
              &nbsp;
              <img
                style={{ position: "relative", top: "-1px" }}
                src={WAN}
                alt="WAN"
              />
            </span>
          </Data>
          <Data>
            <span>My Rewards</span>
            <span className="text-dark-333 dark:text-dark-333">
              {Numeral(storeman.myIncentive)
                .format("0,0.0000")
                .replace(/\.?0+$/, "")}
              &nbsp;
              <img
                style={{ position: "relative", top: "-1px" }}
                src={WAN}
                alt="WAN"
              />
            </span>
          </Data>
        </MyCard>
      </Link>
    </Container>
  );
}

const Container = styled.div<{
  isdark: boolean;
}>`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 250px;

  &:hover .card {
    border: ${(props) =>
      props.isdark ? "2px solid #2FBDF4" : "2px solid #00DFCE"};
    box-shadow: ${(props) =>
      props.isdark ? "0px 0px 30px 0px #2FBDF4" : "0px 0px 30px 0px #0F68AA"};
  }

  &:hover .mycard {
    border: ${(props) =>
      props.isdark ? "2px solid #2FBDF4" : "2px solid #00DFCE"};
    box-shadow: ${(props) =>
      props.isdark ? "0px 0px 30px 0px #2FBDF4" : "0px 0px 30px 0px #0F68AA"};
  }

  &:hover .arrowStyle {
    opacity: 1;
  }

  @media (max-width: 768px) {
    margin-left: 15%;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ArrowStyle = styled.img`
  cursor: pointer;
  opacity: 0;
`;

const Card = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
  backdrop-filter: blur(50px);
  justify-content: space-between;
  border: 2px solid transparent;
  z-index: 2;
  position: relative;

  .ant-progress-text {
    color: white;
    display: flex;
    justify-content: center;
  }
`;

const MyCard = styled.div`
  width: 85%;
  height: 100px;
  margin-bottom: 2rem;
  position: relative;
  top: -15px;
  left: 8%;
  align-items: center;
  padding: 20px 10px 10px 10px;
  border-radius: 16px;
  cursor: pointer;
  opacity: 0.9;
  backdrop-filter: blur(50px);
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;

  .ant-progress-text {
    color: white;
    display: flex;
    justify-content: center;
  }
`;

const Icon = styled.img`
  width: 36px;
  border-radius: 8px;
`;

const IconContainer = styled.div`
  background-color: #ddd;
  width: 36px;
  height: 36px;
  display: inline-block;
  border-radius: 8px;
  margin-right: 0.5rem;
`;

const StoremanName = styled.span`
  margin-left: 5px;
  font-size: 14px;
`;

const BottomArea = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const HeartPercent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Percent = styled.span`
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  color: #0f68aa;
  top: 18%;
  width: 100%;
`;

const FeeRate = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextIcon = styled.span`
  color: #818d96;
  font-size: 12px;
  position: relative;
  top: -5px;
`;

const CardContentCon = styled.div`
  display: flex;
  flex-direction: column;
`;

const StakeData1 = styled.div`
  font-size: 22px;
  line-height: 24px;
  font-family: Inter-Black, Inter;
  margin-top: 3px;
`;

const StakeData2 = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: #ddd;
  margin-top: 3px;
`;

const CardPart1 = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const CardPart2 = styled.div`
  text-align: center;
`;

const Data = styled.div`
  display: flex;
  justify-content: space-between;
  color: #757575;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
`;
