import React, { CSSProperties, ReactNode } from "react";
import styled from "@emotion/styled";
import { Button, message, Spin, Typography } from "antd";
import { TokenType } from "types/token-type";
import { ReactComponent as ETHICON } from "assets/eth-icon.svg";
import { ReactComponent as USDTICON } from "assets/usdt-icon.svg";
import { ReactComponent as USDCICON } from "assets/usdc-icon.svg";
import { ReactComponent as Notice } from "assets/notice.svg";
import WAN from "assets/ETH.png";
import USDT from "assets/USDT.png";
import USDC from "assets/USDC.png";

export const Row = styled.div<{
  gap?: number | boolean;
  between?: boolean;
  marginBottom?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.between ? "space-between" : undefined)};
  margin-bottom: ${(props) => props.marginBottom + "rem"};

  > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: ${(props) =>
      typeof props.gap === "number"
        ? props.gap + "rem"
        : props.gap
        ? "2rem"
        : undefined};
  }
`;

const FullPage = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FullPageLoading = () => (
  <FullPage>
    <Spin size={"large"} />
  </FullPage>
);

export const FullPageErrorFallback = ({ error }: { error: Error | null }) => (
  <FullPage>
    <ErrorBox error={error} />
  </FullPage>
);

// 类型守卫
const isError = (value: any): value is Error => value?.message;

export const ErrorBox = ({ error }: { error: unknown }) => {
  if (isError(error)) {
    return <Typography.Text type={"danger"}>{error?.message}</Typography.Text>;
  }
  return null;
};

export const ButtonNoPadding = styled(Button)`
  padding: 0;
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

export const EndLine = styled.span<{
  height?: string;
}>`
  height: ${(props) => (props.height ? props.height : "18px")};
  border-left: 1px solid #ddd;
  margin: 0 0.5rem;
`;

export const LoadingDataModal = styled.span<{
  width?: string;
}>`
  animation: 2s ease-out 0s infinite normal running none;
  transform: translate3d(0, 0, 0);
  background-color: rgb(233, 234, 235);
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : "2rem")};
`;

// @UPDATE_TOKEN
export const getTokenIcon = (symbol: TokenType, width?: string): ReactNode => {
  switch (symbol) {
    case "WAN":
      return (
        <ETHICON
          key={symbol}
          width={width || "2.8rem"}
          height={width || "2.8rem"}
        />
      );
    case "USDT":
      return (
        <USDTICON
          key={symbol}
          width={width || "2.8rem"}
          height={width || "2.8rem"}
        />
      );
    case "USDC":
      return (
        <USDCICON
          key={symbol}
          width={width || "2.8rem"}
          height={width || "2.8rem"}
        />
      );
  }
};

// @UPDATE_TOKEN
export const getTokenBanner = (symbol: TokenType): string | undefined => {
  switch (symbol) {
    case "WAN":
      return WAN;
    case "USDT":
      return USDT;
    case "USDC":
      return USDC;
  }
};

type MessageType = "info" | "warning" | "loading" | "success";

export const MessageNotice = (
  type: MessageType,
  content: string,
  style?: CSSProperties
) => {
  return message[type]({
    icon: (
      <Notice
        style={{ top: "0.4rem", position: "relative", marginRight: "0.4rem" }}
      />
    ),
    content,
    style,
  });
};
