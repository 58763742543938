import styled from "@emotion/styled";
import Loading from "assets/Loading.png";

export default function LoadingPage() {
  return (
    <Container>
      <Img src={Loading} alt="loading" />
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 400px;
  height: 400px;
`;
