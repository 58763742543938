import { Tooltip } from "antd";
import styled from "@emotion/styled";
import ToolTipIcon from "assets/tooltip.png";
import { useLocalStorage } from "context/localstorage-context";
import { useMemo } from "react";

export default function ToolTipCus({ title }: { title: string }) {
  const { theme } = useLocalStorage();
  const isdark = useMemo(() => theme === "dark", [theme]);

  return (
    <Tooltip
      placement="right"
      title={title}
      color={isdark ? "#1A4055" : "#688290"}
      overlayInnerStyle={{ borderRadius: "12px", fontSize: "12px" }}
    >
      <Img src={ToolTipIcon} alt="1" width={14} style={{ marginLeft: "5px" }} />
    </Tooltip>
  );
}

const Img = styled.img`
  position: relative;
  top: -1px;
`;
