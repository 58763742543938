import Web3 from "web3";
import { useCallback, useMemo } from "react";
import { config, ETHGAS, getCorrespondChain, getDecimal } from "config";
import { Instance } from "types/web3-type";
import { Contract } from "web3-eth-contract";
import { ActionNameToFuncName, ActionType } from "types/action-type";
import { toHexParams } from "utils";
import { ParamsProps, TransParams } from "types/trans-params";
import { RawObject } from "types";
import Erc20Abi from "config/erc20.json";
import { TokenType } from "types/token-type";
import { useWallet } from "context/wallet-context";

const defaultNetworkVersion = "1";

export const useWeb3 = () => {
  const { givenProvider } = Web3;
  return useMemo(() => new Web3(givenProvider), [givenProvider]);
};

export const useWanWeb3 = () => {
  const chainId = getCorrespondChain.get(
    window.ethereum?.networkVersion || defaultNetworkVersion
  );

  return useMemo(() => {
    if (chainId) {
      return new Web3(config[chainId].gwan);
    }
    return null;
  }, [chainId]);
};

export const callContractFunc = async (
  contractName: string,
  endpoint: string,
  instance: Instance
) => {
  const { web3, params } = instance;
  const networkVersion =
    window.ethereum?.networkVersion || defaultNetworkVersion;
  if (typeof config[networkVersion] !== "object") {
    return Promise.reject({ message: "Network Error" });
  }
  const contractConfig = config[networkVersion][contractName];
  //@ts-ignore
  const contractInstance = new web3.eth.Contract(
    contractConfig.abi,
    contractConfig.addr
  );

  if (params) {
    return contractInstance.methods[endpoint](...params)
      .call()
      .then((ret: any) => ret)
      .catch(console.log);
  } else {
    return contractInstance.methods[endpoint]()
      .call()
      .then((ret: any) => ret)
      .catch(console.log);
  }
};

export const useConfig = (
  key: string,
  chainId?: string
): RawObject<any> | null => {
  // if (chainId === undefined) chainId = address ? address[0].chainId : undefined;
  return useMemo(() => {
    const id = chainId
      ? chainId
      : window.ethereum?.networkVersion || defaultNetworkVersion;
    return config[id] ? config[id][key] : null;
  }, [chainId, key]);
};

export const useRecordAirDropContract = () => {
  const chainId = getCorrespondChain.get(
    window.ethereum?.networkVersion || defaultNetworkVersion
  );
  const contractConfig = useConfig("RecordAirDrop", chainId);

  return useMemo(() => {
    if (chainId && contractConfig) {
      const web3 = new Web3(config[chainId].gwan);
      return [
        new web3.eth.Contract(contractConfig.abi, contractConfig.addr),
        contractConfig.addr,
      ] as const;
    }
    return [];
  }, [chainId, contractConfig]);
};

export const useStoremanGroupDelegateContract = () => {
  const chainId = getCorrespondChain.get(
    window.ethereum?.networkVersion || defaultNetworkVersion
  );
  const contractConfig = useConfig("StoremanGroupDelegate", chainId);

  return useMemo(() => {
    if (chainId && contractConfig) {
      const web3 = new Web3(config[chainId].gwan);
      return [
        new web3.eth.Contract(contractConfig.abi, contractConfig.addr),
        contractConfig.addr,
      ] as const;
    }
    return [];
  }, [chainId, contractConfig]);
};

export const usePosContract = () => {
  const { networkId, web3 } = useWallet();
  const contractConfig = useConfig("PosContract", networkId.toString());

  return useMemo(() => {
    if (networkId && contractConfig && web3) {
      return [
        new web3.eth.Contract(contractConfig.abi, contractConfig.addr),
        contractConfig.addr,
      ] as const;
    }
    return [];
  }, [contractConfig, networkId, web3]);
};

export const useStoremanContract = () => {
  const { networkId, web3 } = useWallet();
  const contractConfig = useConfig("StoremanContract", networkId.toString());

  return useMemo(() => {
    if (networkId && contractConfig && web3) {
      return [
        new web3.eth.Contract(contractConfig.abi, contractConfig.addr),
        contractConfig.addr,
      ] as const;
    }
    return [];
  }, [contractConfig, networkId, web3]);
};

export const useErc20Contract = (tokenType: TokenType) => {
  const web3 = useWeb3();
  const contractConfig = useConfig("tokenAddrArr");

  return useMemo(() => {
    if (contractConfig && tokenType !== "WAN") {
      return [
        //@ts-ignore
        new web3.eth.Contract(Erc20Abi, contractConfig[tokenType]),
        contractConfig[tokenType],
      ] as const;
    }
    return [];
  }, [contractConfig, tokenType, web3.eth.Contract]);
};

export const useCallContractFunc = () => {
  const web3 = useWeb3();
  return useCallback(
    (
      ...[contractName, endpoint, instance]: Parameters<typeof callContractFunc>
    ) => callContractFunc(contractName, endpoint, { ...instance, web3 }),
    [web3]
  );
};

export const useRecordAirDropFunc = () => {
  const [contractInstance] = useRecordAirDropContract();
  return useCallback(
    (endpoint: string, params: string[]) =>
      contractInstance.methods[endpoint](...params)
        .call()
        .then((ret: any) => ret),
    [contractInstance]
  );
};

export const useRecordAirDropEvent = () => {
  const [contractInstance] = useRecordAirDropContract();
  return useCallback(
    (event: string, options: object) =>
      contractInstance.getPastEvents(event, options).then((ret: any) => ret),
    [contractInstance]
  );
};

export const useStoremanGroupDelegateFunc = () => {
  const [contractInstance] = useStoremanGroupDelegateContract();
  return useCallback(
    (endpoint: string, params: string[]) =>
      contractInstance.methods[endpoint](...params)
        .call()
        .then((ret: any) => ret),
    [contractInstance]
  );
};

export const useSendPosContractFunc = () => {
  const [contractInstance] = usePosContract();
  const { address: from } = useWallet();
  return useCallback(
    (action: string, params: string[], value?: string) =>
      contractInstance &&
      contractInstance.methods[action](...params).send({
        from,
        value: value || "0",
      }),
    [contractInstance, from]
  );
};

export const useSendStoremanContractFunc = () => {
  const [contractInstance] = useStoremanContract();
  const { address: from } = useWallet();
  return useCallback(
    (action: string, params: string[], value?: string) =>
      contractInstance &&
      contractInstance.methods[action](...params).send({
        from,
        value: value || "0",
      }),
    [contractInstance, from]
  );
};

export const encodeABIFunc = (
  contractInstance: Contract,
  endpoint: string,
  params: string[]
) => {
  return contractInstance.methods[endpoint](...params).encodeABI();
};

export const createTransParams = (
  contractAddr: string,
  config: ReturnType<typeof useConfig>,
  contractInstance: Contract,
  action: ActionType,
  params: ParamsProps
): Partial<TransParams> | null => {
  const { tokenType, stakeAmount, address } = params;

  if (!config || !address) {
    return null;
  }

  let txParams = {
    to: contractAddr,
    value:
      tokenType === "WAN" && action === "Deposit"
        ? toHexParams(stakeAmount || "0", 18)
        : "0x0",
    from: address,
  };
  switch (action) {
    case "Deposit":
      return Object.assign(txParams, {
        data: encodeABIFunc(contractInstance, ActionNameToFuncName(action), [
          config[tokenType],
          toHexParams(stakeAmount, getDecimal.get(tokenType)),
        ]),
      });
    case "Unstake":
      return Object.assign(txParams, {
        data: encodeABIFunc(contractInstance, ActionNameToFuncName(action), [
          config[tokenType],
        ]),
      });
    case "Withdraw":
      return Object.assign(txParams, {
        data: encodeABIFunc(contractInstance, ActionNameToFuncName(action), [
          config[tokenType],
          address,
        ]),
        gas: toHexParams(ETHGAS, 0),
      });
    default:
      return null;
  }
};
