import numeral from "numeral";
import { useMemo } from "react";
import BigNumber from "bignumber.js";
import styled from "@emotion/styled";
import WanPos from "assets/wanPos.png";
import Rewards from "assets/rewards.png";
import MyRewards from "assets/myrewards.png";
import StoremanLists from "./storeman-lists";
import { useStoremanDelegatorInfo } from "./storeman-data";
import {
  useQueryStoremanInfo,
  useQueryStatsInfo,
  useQueryTokenPrice,
  useQueryStoremanConf,
} from "utils/queries";
import Identicon from "identicon.js";
import { fromWei1 } from "utils";
import { useLocalStorage } from "context/localstorage-context";

export default function Bridge() {
  const { data: statsInfo } = useQueryStatsInfo();
  const storemanInfo = useQueryStoremanInfo();
  const { theme } = useLocalStorage();
  const storemanDelegatorInfo = useStoremanDelegatorInfo();
  const { data: tokensPrice, isLoading: tokensPriceLoading } =
    useQueryTokenPrice();
  const storemanConf = useQueryStoremanConf();
  const isdark = useMemo(() => theme === "dark", [theme]);

  const storemanList = useMemo(() => {
    if (storemanInfo && storemanInfo.length > 0) {
      storemanInfo.forEach((i: any) => {
        i.myDeposit = "0";
        i.myIncentive = "0";
        i.quota = "0";
        storemanDelegatorInfo.forEach((j: any) => {
          if (i.wkAddr.toLowerCase() === j.wkAddr.toLowerCase()) {
            i.myDeposit = j.deposit.toString();
            i.myIncentive = j.incentive.toString();
          }
        });
        if (storemanConf) {
          const quota = new BigNumber(fromWei1(i.deposit))
            .plus(fromWei1(i.partnerDeposit || "0"))
            .multipliedBy(storemanConf.delegationMulti || "1")
            .minus(fromWei1(i.delegateDeposit || "0"))
            .toString(10);
          i.quota = numeral(quota).format("0,0");
        }
      });
      return storemanInfo;
    } else {
      return [];
    }
  }, [storemanDelegatorInfo, storemanInfo, storemanConf]);

  const storemanListMydelegate = useMemo(() => {
    if (storemanInfo && storemanInfo.length > 0) {
      let ret: any[] = [];

      storemanDelegatorInfo.forEach((i: any) => {
        const tmp = storemanInfo.find(
          (j: any) => i.wkAddr.toLowerCase() === j.wkAddr.toLowerCase()
        );
        if (!tmp) {
          i.name = i.wkAddr;
          i.storeman = "";
          i.activity = "0";
          i.delegateFee = "0";
          i.imgdata = `data:image/png;base64,${new Identicon(
            i.wkAddr
          ).toString()}`;
          i.myDeposit = i.deposit.toString();
          i.myIncentive = i.incentive.toString();
          ret.push(i);
        }
      });
      return ret;
    } else {
      return [];
    }
  }, [storemanDelegatorInfo, storemanInfo]);

  const myAllDelegates = useMemo(() => {
    return storemanDelegatorInfo.reduce(
      (prev: string, i: { deposit: string }) =>
        new BigNumber(prev).plus(i.deposit).toString(),
      0
    );
  }, [storemanDelegatorInfo]);

  const myTotalIncentive = useMemo(() => {
    return storemanDelegatorInfo.reduce(
      (prev: string, i: { incentive: string }) =>
        new BigNumber(prev).plus(i.incentive).toString(),
      0
    );
  }, [storemanDelegatorInfo]);

  const storemanTvl = useMemo(() => {
    if (statsInfo && statsInfo.sotremen) {
      return statsInfo.sotremen.totalDeposit;
    } else {
      return 0;
    }
  }, [statsInfo]);

  const WanPrice = useMemo(() => {
    if (!tokensPriceLoading && tokensPrice) {
      return tokensPrice.wanchain?.usd || "0";
    } else {
      return "0";
    }
  }, [tokensPrice, tokensPriceLoading]);

  const storemanTvlInUsd = useMemo(() => {
    if (statsInfo && statsInfo.sotremen) {
      const storemanTvl = new BigNumber(statsInfo.sotremen.totalDepositRaw)
        .dividedBy(Math.pow(10, 18))
        .toString(10);
      const storemanTvlNumber = numeral(storemanTvl).value();
      const usdTvl = new BigNumber(WanPrice)
        .multipliedBy(storemanTvlNumber || "0")
        .toString();
      return numeral(usdTvl).format("0,0");
    } else {
      return 0;
    }
  }, [WanPrice, statsInfo]);

  const myAllDelegatesInUsd = useMemo(() => {
    const tmp = new BigNumber(WanPrice)
      .multipliedBy(myAllDelegates || "0")
      .toString();
    return numeral(tmp).format("0,0");
  }, [WanPrice, myAllDelegates]);

  const storemanRewardRate = useMemo(() => {
    if (statsInfo && statsInfo.sotremen) {
      return statsInfo.sotremen.rewardRatio;
    } else {
      return 0;
    }
  }, [statsInfo]);

  return (
    <Container>
      {/* Total  */}
      <BridgeStatistics className="bg-white dark:bg-common-1">
        <StatisticsTvl>
          <StatisticsTitle className="text-light-0 dark:text-dark-0">
            Storemen TVL
            <BridgeTvlInUsd>
              <Data1>{storemanTvlInUsd} USD</Data1>
            </BridgeTvlInUsd>
          </StatisticsTitle>
          <Con>
            <Img src={WanPos} alt="WanPos" />
            <Data>
              <div className="text-light-111 dark:text-white">
                {storemanTvl} <Data1>WAN</Data1>
              </div>
              <BridgeTvlInUsdMedia>
                <Data1>{storemanTvlInUsd} USD</Data1>
              </BridgeTvlInUsdMedia>
            </Data>
          </Con>
        </StatisticsTvl>
        <LineStyle isdark={isdark} />
        <StatisticsAvg>
          <StatisticsTitle className="text-light-0 dark:text-dark-0">
            Average APR
          </StatisticsTitle>
          <Con>
            <Img src={Rewards} alt="Rewards" />
            <Data className="text-light-222 dark:text-white">
              {storemanRewardRate}
            </Data>
          </Con>
        </StatisticsAvg>
      </BridgeStatistics>

      <Gap />

      {/* Personal  */}
      {new BigNumber(myAllDelegates).gt("0") && (
        <BridgeStatistics className="bg-white dark:bg-common-1">
          <StatisticsTvl>
            <StatisticsTitle className="text-light-0 dark:text-dark-0">
              My Delegations
              <BridgeTvlInUsd>
                <Data1>{myAllDelegatesInUsd} USD</Data1>
              </BridgeTvlInUsd>
            </StatisticsTitle>
            <Con>
              <Img src={WanPos} alt="WanPos" />
              <Data>
                <div className="text-light-111 dark:text-white">
                  {numeral(myAllDelegates)
                    .format("0,0.00")
                    .replace(/\.?0+$/, "")}{" "}
                  <Data1>WAN</Data1>
                </div>
                <BridgeTvlInUsdMedia>
                  <Data1>{myAllDelegatesInUsd} USD</Data1>
                </BridgeTvlInUsdMedia>
              </Data>
            </Con>
          </StatisticsTvl>
          <LineStyle isdark={isdark} />
          <StatisticsAvg>
            <StatisticsTitle className="text-light-0 dark:text-dark-0">
              My Rewards
            </StatisticsTitle>
            <Con>
              <Img src={MyRewards} alt="Rewards" />
              <Data>
                <div className="text-light-222 dark:text-white">
                  {numeral(myTotalIncentive)
                    .format("0,0.0000")
                    .replace(/\.?0+$/, "")}{" "}
                  <Data1>WAN</Data1>
                </div>
              </Data>
            </Con>
          </StatisticsAvg>
        </BridgeStatistics>
      )}
      <StoremanLists
        storemanList={storemanList}
        withdrawStoreman={storemanListMydelegate}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 2rem 8%;
  text-align: center;
  height: 100%;
`;

const BridgeStatistics = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  padding: 0 2rem;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StatisticsTvl = styled.div`
  width: 50%;
  padding: 2rem 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BridgeTvlInUsd = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: inline-block;
  }
`;

const BridgeTvlInUsdMedia = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Con = styled.div`
  position: relative;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StatisticsTitle = styled.div`
  font-size: 18px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

const StatisticsAvg = styled.div`
  width: 50%;
  padding: 2rem 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Img = styled.img`
  width: 3rem;
  margin-top: 5px;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  position: absolute;
`;

const Data = styled.div`
  font-size: 28px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  justify-content: space-between;
  display: flex;
  width: calc(100% - 4rem);
  padding: 0 1rem 0 4rem;
`;

const Data1 = styled.span`
  color: #808080;
  font-size: 15px;
`;

const LineStyle = styled.div<{
  isdark: boolean;
}>`
  height: 100%;
  border-left: ${(props) =>
    props.isdark ? "1px solid rgba(255, 255, 255, 0.1)" : "1px solid #EBEFF2"};
  margin: 0 2rem;

  @media (max-width: 768px) {
    width: 100%;
    border-bottom: ${(props) =>
      props.isdark
        ? "1px solid rgba(255, 255, 255, 0.1)"
        : "1px solid #EBEFF2"};
    margin: 2rem 0;
  }
`;

const Gap = styled.div`
  width: 100%;
  margin: 1rem 0;
`;
