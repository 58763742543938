import PosAbi from "config/PosAbi.json";
import StoremanGroupDelegateAbi from "config/StoremanGroupDelegateAbi.json";
import RecordAirDrop from "config/RecordAirDropAbi.json";
import StoremanGroupDelegate from "config/StoremanGroupDelegateAbi.json";
import MultiCoinStakeDelegate from "config/MultiCoinStakeDelegateAbi.json";
import { RawObject } from "types";

export const config: RawObject<RawObject<any>> = {
  // Ethereum Main Network (Mainnet)
  "1": {
    MultiCoinStakeDelegate: {
      addr: "0x71d53A174B7FeC5115FD36A01FB8D905A97a5702",
      abi: MultiCoinStakeDelegate,
    },
    tokenAddrArr: {
      ETH: "0x0000000000000000000000000000000000000000",
      USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    },
  },
  // Rinkeby Test Network
  "4": {
    MultiCoinStakeDelegate: {
      addr: "0x71d53A174B7FeC5115FD36A01FB8D905A97a5702",
      abi: MultiCoinStakeDelegate,
    },
    tokenAddrArr: {
      ETH: "0x0000000000000000000000000000000000000000",
      USDT: "0x28c96b26f6df3cf57a0a4e8fef02e9295e9ca458",
      USDC: "0x6f2ccb2a1f800705fd70fd77f821078ec9d19f07",
    },
  },
  // Wanchain Main Network
  "888": {
    gwan: "https://gwan-ssl.wandevs.org:56891",
    RecordAirDrop: {
      addr: "0x69210E65afE612b47C6bAb5D8DE241b7eCE46c51",
      abi: RecordAirDrop,
    },
    StoremanGroupDelegate: {
      addr: "0x1E7450D5d17338a348C5438546f0b4D0A5fbeaB6",
      abi: StoremanGroupDelegate,
    },
    tokenAddrArr: {
      ETH: "0x0000000000000000000000000000000000000000",
      USDT: "0x3D5950287b45F361774E5fB6e50d70eEA06Bc167",
      USDC: "0x52A9CEA01c4CBDd669883e41758B8eB8e8E2B34b",
    },
    PosContract: {
      addr: "0x00000000000000000000000000000000000000DA",
      abi: PosAbi,
    },
    StoremanContract: {
      addr: "0x1E7450D5d17338a348C5438546f0b4D0A5fbeaB6",
      abi: StoremanGroupDelegateAbi,
    },
  },
  // Wanchain Test Network
  "999": {
    gwan: "https://gwan-ssl.wandevs.org:46891",
    RecordAirDrop: {
      addr: "0x69210E65afE612b47C6bAb5D8DE241b7eCE46c51",
      abi: RecordAirDrop,
    },
    StoremanGroupDelegate: {
      addr: "0xaA5A0f7F99FA841F410aafD97E8C435c75c22821",
      abi: StoremanGroupDelegate,
    },
    tokenAddrArr: {
      ETH: "0x0000000000000000000000000000000000000000",
      USDT: "0x28c96b26f6df3cf57a0a4e8fef02e9295e9ca458",
      USDC: "0x6f2ccb2a1f800705fd70fd77f821078ec9d19f07",
    },
    PosContract: {
      addr: "0x00000000000000000000000000000000000000DA",
      abi: PosAbi,
    },
    StoremanContract: {
      addr: "0xaA5A0f7F99FA841F410aafD97E8C435c75c22821",
      abi: StoremanGroupDelegateAbi,
    },
  },
};

export const tokensName = [
  "ethereum",
  "tether",
  "usd-coin",
  "wanchain",
] as const; // "wanchain" always be the last one
export const tokensSymbol = ["WAN", "USDT", "USDC"] as const;

export const getDecimal = new Map([
  ["ETH", 18],
  ["USDT", 6],
  ["USDC", 6],
  ["WAN", 18],
]);

export const getTokenName = new Map([
  ["ETH", "ethereum"],
  ["USDT", "tether"],
  ["USDC", "usd-coin"],
]);

export const getCorrespondChain = new Map([
  ["1", "888"],
  ["4", "999"],
]);

export const ETHGAS = "1000000";

export const MAINNET_INFO = {
  chainId: "0x378",
  chainName: "Wanchain",
  nativeCurrency: {
    name: "WAN",
    symbol: "WAN",
    decimals: 18,
  },
  rpcUrls: ["https://gwan-ssl.wandevs.org:56891/"],
  blockExplorerUrls: ["https://www.wanscan.org"],
};

export const TESTNET_INFO = {
  chainId: "0x3e7",
  chainName: "Wanchain Testnet",
  nativeCurrency: {
    name: "WAN",
    symbol: "WAN",
    decimals: 18,
  },
  rpcUrls: ["https://gwan-ssl.wandevs.org:46891/"],
  blockExplorerUrls: ["https://testnet.wanscan.org"],
};

export const PoSDataToolTip = [
  "This indicates the number of WAN staked in the node (including delegations)",
  "This represents the remaining amount available for delegation.",
  "This indicates the node's online rate, where an activity level of 100% means the node is continuously online and operating normally, and an activity level of 0% means the node is completely inactive. The closer the activity level is to 100%, the higher the node's activity.",
  "This represents the lock-up period set by the node, which ranges from 7 to 90 days. The longer the lock-up period, the higher the node's yield rate. The average yield rate for a 90-day lock-up period is 1.5 times that of a 7-day period.",
  "This represents the delegation fee rate currently set by the node. This fee is deducted from the delegator's daily earnings. For example, if a delegator earns 10 WAN and the fee rate is 10%, the actual earnings would be 9 WAN.",
  "This indicates the maximum delegation fee rate that can be set by the node. Node operators can adjust the delegation fee by one percentage point daily, but it cannot exceed the node's maximum fee. The maximum fee is determined when the node is created and cannot be changed.",
  'This indicates the current status of the node. "Working" means operating normally.',
  "This indicates when the node will exit. N/A means the node currently has no plans to exit.",
];

export const BridgeDataToolTip = [
  "This indicates the number of WAN staked in the node (including delegations)",
  "This represents the remaining amount available for delegation.",
  "This indicates the node's online rate, where an activity level of 100% means the node is continuously online and operating normally, and an activity level of 0% means the node is completely inactive. The closer the activity level is to 100%, the higher the node's activity.",
  "This represents the delegation fee rate currently set by the bridge. This fee is deducted from the delegator's daily earnings. For example, if a delegator earns 10 WAN and the fee rate is 10%, the actual earnings would be 9 WAN.",
  'This indicates the current status of the node. "Working" means operating normally.',
  "This indicates if the node is automatically eligible for election in the next cycle. True means that the node is already eligible and that the operator does not need to take any action.",
];
