import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import PageHeader from "components/page-header";
import MainBody from "screens/main-body";
import Pos from "screens/pos";
import PosDetail from "screens/pos/pos-detail";
import StoremanDetail from "screens/bridge/storeman-detail";
import Bridge from "screens/bridge";
import Aside from "screens/Aside";
import PageFooter from "components/page-footer";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { useWallet } from "context/wallet-context";
import { AddEthereumChainParameter } from "types/wallet-type";
import { TESTNET_INFO, MAINNET_INFO } from "config";
import MenuToggle from "assets/menuToggle.png";
import MenuToggle_Light from "assets/menuToggle_light.png";
import { useLocalStorage } from "context/localstorage-context";

export default function HomePage() {
  const [toggled, setToggled] = useState(false);
  const location = useLocation();
  const { networkId: chainId } = useWallet();
  const handleToggleSidebar = (value: boolean) => {
    setToggled(value);
  };

  useEffect(() => {
    if (
      window.ethereum &&
      Number(chainId) !== Number(process.env.REACT_APP_CHAINID)
    ) {
      const params: AddEthereumChainParameter = (
        process.env.REACT_APP_CHAINID === "888" ? MAINNET_INFO : TESTNET_INFO
      ) as AddEthereumChainParameter;

      (window.ethereum as any)
        .request({
          method: "wallet_addEthereumChain",
          params: [params],
        })
        .then((result: any) => {
          console.debug(result);
        })
        .catch((error: any) => {
          console.debug(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="bg-theme-light dark:bg-theme-dark  md:bg-theme-light-media md:dark:bg-theme-dark-media">
      <Aside toggled={toggled} handleToggleSidebar={handleToggleSidebar} />
      <MainScreen
        pathname={location.pathname}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      />
    </Container>
  );
}

const MainScreen = ({
  toggled,
  handleToggleSidebar,
  pathname,
}: {
  pathname: string;
  toggled: boolean;
  handleToggleSidebar: (value: boolean) => void;
}) => {
  const isHomepage = useMemo(() => pathname !== "/", [pathname]);
  const { theme } = useLocalStorage();
  const isdark = useMemo(() => theme === "dark", [theme]);

  return (
    <Main isHomepage={isHomepage}>
      <Part1 isdark={isdark}>
        <BtnToggle onClick={() => handleToggleSidebar(true)}>
          <img
            src={isdark ? MenuToggle : MenuToggle_Light}
            alt="menuToggle"
            width={40}
          />
        </BtnToggle>
        <PageHeader />
      </Part1>
      <MBody isHomepage={isHomepage}>
        <Routes>
          <Route path={"/"} element={<MainBody />} />
          <Route path={"/pos"} element={<Pos />} />
          <Route path={"/bridge"} element={<Bridge />} />
          <Route path={"/posdetail/:id"} element={<PosDetail />} />
          <Route path={"/storemandetail/:id"} element={<StoremanDetail />} />
          <Navigate to={"/"} />
        </Routes>
        {/* {isHomepage && <PageFooter />} */}
      </MBody>
      {!isHomepage && <PageFooter />}
    </Main>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  background-size: cover;
  height: 100%;

  .pro-sidebar {
    z-index: 999;
  }
`;

const Main = styled.div<{
  isHomepage: boolean;
}>`
  padding: 24px 0 10px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    !props.isHomepage ? "space-between" : undefined};
  overflow: hidden;

  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

const Part1 = styled.div<{
  isdark: boolean;
}>`
  @media (max-width: 768px) {
    background-color: ${(props) => (props.isdark ? "#051f31" : "#fff")};
    padding: 5px;
    width: 100%;
    height: 70px;
    line-height: 70px;
    display: flex;
    justify-content: space-between;
  }
`;

const BtnToggle = styled.div`
  cursor: pointer;
  width: 60px;
  height: 60px;
  color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MBody = styled.div<{
  isHomepage: boolean;
}>`
  overflow-x: ${(props) => (props.isHomepage ? "hidden" : "auto")};
  overflow-y: ${(props) => (props.isHomepage ? "scroll" : "none")};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.isHomepage ? "space-between" : "center"};
  flex: 1 1 auto;
  gap: 1rem;
  scrollbar-width: none;

  @media (max-width: 768px) {
    padding-top: 100px;
    margin-bottom: 2rem;
  }
`;
