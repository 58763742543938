import BigNumber from "bignumber.js";
import { useWallet } from "context/wallet-context";
import { useMemo } from "react";
import { useIwan } from "utils/useWebSocket";

interface StakeInfo {
  totalStake: string;
  quota: string;
  activity: string;
  lockPeriod: string;
  status: string;
  maxFee: string;
  delegationFee: string;
  address: string;
  feeRate: string;
  imgData?: string;
}

const INSTANCE_STAKE_INFO: StakeInfo = {
  totalStake: "0",
  quota: "0",
  activity: "0",
  lockPeriod: "0",
  status: "Working",
  maxFee: "0",
  delegationFee: "0",
  address: "0x",
  feeRate: "0",
};

export const useCurrentStakeInfo = () => {
  const data = useIwan("getCurrentStakerInfo", { chainType: "WAN" }, true);
  return useMemo(() => {
    const ret: StakeInfo[] = [];
    if (data) {
      data.forEach((i: any) => {
        const item = Object.assign({}, INSTANCE_STAKE_INFO);
        item.totalStake = i.clients
          .reduce(
            (prev: BigNumber, curr: any) => prev.plus(curr.amount),
            new BigNumber("0")
          )
          .plus(i.amount)
          .dividedBy(Math.pow(10, 18))
          .toString();
        item.address = i.address;
        ret.push(item);
      });
      return ret;
    } else {
      return [];
    }
  }, [data]);
};

export const useDelegatorStakeInfo = () => {
  const { address } = useWallet();
  const data = useIwan(
    "getDelegatorStakeInfo",
    { chainType: "WAN", address },
    !!address
  );
  return useMemo(() => {
    if (data) {
      data.forEach(
        (v: any) =>
          (v.amount = new BigNumber(v.amount).dividedBy(
            Math.pow(10, 18).toString(10)
          ))
      );
      data.done = true;
      return data;
    } else {
      return [];
    }
  }, [data]);
};

export const useDelegatorTotalIncentive = () => {
  const { address } = useWallet();
  const data = useIwan(
    "getDelegatorTotalIncentive",
    { chainType: "WAN", address },
    !!address
  );
  return useMemo(() => {
    if (data) {
      data.forEach(
        (v: any) =>
          (v.amount = new BigNumber(v.amount).dividedBy(
            Math.pow(10, 18).toString(10)
          ))
      );
      return data;
    } else {
      return [];
    }
  }, [data]);
};
