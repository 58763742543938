import "./App.css";
import 'react-pro-sidebar/dist/css/styles.css'
import { ErrorBoundary } from "react-error-boundary";
import { FullPageErrorFallback } from "components/lib";
import { useLoading } from "utils";
import HomePage from "screens";
import LoadingPage from "screens/loading-page";

export default function App() {
  const { isLoading } = useLoading();

  return (
    <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
      {isLoading ? <LoadingPage /> : <HomePage />}
    </ErrorBoundary>
  );
}
