import styled from "@emotion/styled";
import { useMemo } from "react";
import { useQueryTokenPrice } from "utils/queries";
import Numeral from "numeral";
import WAN from "assets/WANPrice.png";

export default function WanPrice() {
  const { data: tokensPrice, isLoading: tokensPriceLoading } =
    useQueryTokenPrice();

  const WanPrice = useMemo(() => {
    if (!tokensPriceLoading && tokensPrice) {
      return tokensPrice.wanchain?.usd || "0";
    } else {
      return "0";
    }
  }, [tokensPrice, tokensPriceLoading]);

  return (
    <Container>
      <PriceStyle>
        <Img src={WAN} alt="wanIcon" />
        <span>{Numeral(WanPrice).format("$0,0.0000")}</span>
      </PriceStyle>
    </Container>
  );
}

const Container = styled.div`
  margin-right: 1rem;
`;

const Img = styled.img`
  left: 2px;
  width: 26px;
  position: relative;
  top: -2px;
`;

const PriceStyle = styled.div`
  position: relative;
  min-width: 11rem;
  height: 3rem;
  line-height: 3rem;
  text-align: right;
  border-radius: 18px;
  background-color: #2fbdf4;
  width: 100%;
  color: #fff;
  padding-right: 1rem;

  span {
    display: inline-block;
    margin-left: 10px;
  }
`;
