import qs from "qs";
import { useCallback } from "react";
import { RawObject } from "types";

const apiUrl = "https://api.coingecko.com/api/v3/simple/price";

interface Config extends RequestInit {
  params?: RawObject<unknown>;
  url?: string;
  strict?: boolean;
}

export const http = async ({
  params,
  url,
  strict,
  ...customConfig
}: Config = {}) => {
  let endpoint = "";
  const config = {
    method: "GET",
    // headers: {
    //   "Content-Type": params ? "application/json" : "",
    // },
    ...customConfig,
  };

  if (config.method.toUpperCase() === "GET") {
    endpoint += strict ? "" : `?${qs.stringify(params, { encode: false })}`;
  } else {
    config.body = JSON.stringify(params || {});
  }

  return window
    .fetch(
      !!endpoint ? `${url || apiUrl}/${endpoint}` : `${url || apiUrl}`,
      config
    )
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
};

export const useHttp = () => {
  return useCallback(
    (...[config]: Parameters<typeof http>) => http({ ...config }),
    []
  );
};
